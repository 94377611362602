import { Panel } from '@crazyegginc/hatch';

import { OptionsHeader } from '../components/OptionsHeader';

// eslint-disable-next-line no-restricted-syntax
export default function IntegrationsOptions() {
  return (
    <>
      <OptionsHeader>Third-Party Integrations</OptionsHeader>
      <Panel>
        <zapier-app-directory
          app="crazy-egg"
          link-target="new-tab"
          theme="light"
          create-without-template="show"
          use-this-zap="show"
        />
      </Panel>
    </>
  );
}
