import { useMemo, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Formik, Form, useFormikContext } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';
import { Button, Spinner, Autocomplete } from '@crazyegginc/hatch';

import { useModal, useMutation, useNotifications, useSelectedSite, usePrevious } from '/src/hooks';
import { createNotificationMutation, editNotificationMutation } from '../../mutations';
import { channelsListQuery } from '../../queries';
import { DeleteWarningModal } from './DeleteWarningModal';
import { Modal } from '/src/contexts/modal';

import { AUTH_TOKEN_KEY } from '/src/features/_global/constants';
import { RESOURCE_META, NOTIFICATION_TYPES } from '../../constants';

const validationSchema = yup.object().shape({
  channel: yup.string().required('Please select a channel.'),
});

export function SlackModal({ notification, resourceType, resourceId, BackToModal, closeAfterAction = false }) {
  const modal = useModal();
  const [polling, setPolling] = useState(false);
  const notifications = useNotifications();
  const { selectedSite } = useSelectedSite();

  const siteId = notification?.siteId ?? selectedSite.id;
  const editing = !!notification;

  const createMutation = useMutation(createNotificationMutation);
  const editMutation = useMutation(editNotificationMutation);
  const mutation = editing ? editMutation : createMutation;

  const { action, actionText } = RESOURCE_META[resourceType];

  const { data, isFetching } = useQuery({
    ...channelsListQuery({ siteId, type: NOTIFICATION_TYPES.SLACK }),
    refetchInterval: polling ? 2000 : false,
  });

  const channels = useMemo(() => data?.channelsList?.filter((c) => Boolean(c.destination)) ?? [], [data]);

  return (
    <Modal>
      <Modal.Title>{editing ? 'Edit Slack Notification' : 'Get Notified via Slack'}</Modal.Title>

      <div className="text-body-2 mb-3.75">
        {editing
          ? `You’re currently being notified in Slack channel ${notification.channel.destination} when ${actionText}.`
          : `We’ll send you a notification in Slack when ${actionText}. Which channel do you want to send these notifications to?`}
      </div>

      <Formik
        initialValues={{
          channel: editing ? notification.channel.destination : '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const existingChannel = data?.channelsList.find((c) => c.destination === values.channel);
          const vars = editing
            ? {
                notificationId: notification.id,
                siteId: notification.siteId,
                channelId: existingChannel ? existingChannel.id : null,
                channelDestination: existingChannel ? null : values.channel,
              }
            : {
                action,
                channelId: existingChannel ? existingChannel.id : null,
                channelDestination: existingChannel ? null : values.channel,
                channelType: NOTIFICATION_TYPES.SLACK,
                resource: resourceType,
                resourceId: String(resourceId),
              };
          mutation.mutate(vars, {
            onError: (error) => {
              const isDuplicateError = error.graphQLErrors?.[0]?.originalError?.message?.match(
                /notification: has already been taken/,
              );

              notifications.error({
                title: `Failed to ${editing ? 'edit' : 'create'} notification`,
                content: isDuplicateError ? 'This notification already exists.' : 'Please try again.',
                timeout: 4000,
                context: { error },
                skipHoneybadger: isDuplicateError ? true : false,
              });
            },
            onSuccess: () => {
              notifications.success({
                content: `Notification ${editing ? 'edited' : 'created'} successfully.`,
                timeout: 3000,
              });
              if (closeAfterAction) {
                modal.close();
              } else {
                modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />);
              }
            },
          });
        }}
      >
        <Form className="w-full">
          <FormContent
            channels={channels}
            isFetching={isFetching}
            polling={polling}
            setPolling={setPolling}
            siteId={siteId}
          />
          <Modal.Actions className="justify-between">
            <div className="flex items-center space-x-2.5">
              <Button disabled={mutation.isLoading} type="submit">
                {mutation.isLoading && (
                  <span className="mr-2.5">
                    <Spinner />
                  </span>
                )}
                {editing && (mutation.isLoading ? 'Saving...' : 'Save')}
                {!editing && (mutation.isLoading ? 'Creating...' : 'Create notification')}
              </Button>

              <Button
                variant="cancel"
                onClick={() =>
                  BackToModal
                    ? modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />)
                    : modal.close()
                }
                className="disabled:bg-transparent"
              >
                {editing ? 'Cancel' : 'Back'}
              </Button>
            </div>
            {editing ? (
              <Button
                variant="warning"
                onClick={() => {
                  modal.show(
                    <DeleteWarningModal
                      id={notification.id}
                      resourceType={notification.resource}
                      resourceId={notification.resourceId}
                      BackToModal={BackToModal}
                    />,
                  );
                }}
              >
                Delete notification
              </Button>
            ) : null}
          </Modal.Actions>
        </Form>
      </Formik>
    </Modal>
  );
}

function FormContent({ channels, isFetching, setPolling, polling, siteId }) {
  const { values, touched, errors, setFieldValue } = useFormikContext();

  const channelOptions = useMemo(() => (channels ?? []).map((c) => ({ value: c.destination })), [channels]);
  const prevChannels = usePrevious(channels);

  // Automatically select new channel when polling is on
  useEffect(() => {
    if (!isFetching && polling && prevChannels.length !== channels.length) {
      const newChannel = channels.find((c) => prevChannels.every((p) => p.id !== c.id));
      if (newChannel) {
        setFieldValue('channel', newChannel.destination);
        setPolling(false);
      }
    }
  }, [channels, prevChannels, isFetching, setFieldValue, polling, setPolling]);

  const token = window.localStorage.getItem(AUTH_TOKEN_KEY);

  return (
    <div className="w-full flex items-start">
      {(isFetching || channelOptions.length > 0) && (
        <div className="w-72 shrink-0 grow-0 flex items-start">
          <div className="w-full">
            <Autocomplete
              label="Slack Channel:"
              labelClassName="font-semibold"
              options={channelOptions}
              value={values.channel}
              onChange={(value) => setFieldValue('channel', value)}
              renderInPortal={true}
              popperClassName="!z-[100000]"
              error={touched.channel && errors.channel ? errors.channel : null}
              loading={isFetching}
            />
          </div>
          <div className="h-[35px] flex items-center ml-3.5 mr-2 mt-[23px] grow-0 leading-none">or</div>
        </div>
      )}
      <div
        className={classNames('h-[35px] flex items-center', {
          'mt-[23px]': isFetching || channelOptions.length > 0,
        })}
      >
        <a
          onClick={() => setPolling(true)}
          href={`https://channels.crazyegg.com/slack/oauth?site_id=${siteId}&create=true&token=${token}`}
          className="text-link leading-none mt-0.5"
          target="_blank"
          rel="noopener noreferrer"
        >
          {isFetching || channelOptions.length > 0 ? 'Add a different channel' : 'Add a Slack channel'}
        </a>
      </div>
    </div>
  );
}
