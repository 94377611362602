import { useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { EditNotificationsModal } from './modals/EditNotificationsModal';
import { NOTIFICATION_TYPES, NOTIFICATIONS_META } from '../constants';
import { ROOT_ELEMENT_ID } from '/src/features/_global/constants';

export function AddNotificationDropdown({ resourceType, resourceId }) {
  const modal = useModal();
  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: () => {
              return [0, 5];
            },
          },
        }),
        [],
      ),
    ],
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              as={Button}
              variant="secondary"
              size="lg"
              className={classNames('relative pr-10', { '!border-dodger-blue-500': open })}
            >
              Add notification
              <Indicator type="dropdown" className="absolute right-4 top-1/2 -translate-y-1/2" />
            </Popover.Button>
          </div>

          {open
            ? createPortal(
                <StyledPopoverPanel
                  ref={setTooltipElement}
                  style={{ ...styles.popper }}
                  {...attributes.popper}
                  className="!z-[100000] w-36"
                >
                  {() => (
                    <>
                      {Object.values(NOTIFICATION_TYPES).map((type) => {
                        const { Icon, displayName, Modal } = NOTIFICATIONS_META[type];
                        return (
                          <PopoverItem
                            key={type}
                            onClick={() => {
                              modal.show(
                                <Modal
                                  resourceType={resourceType}
                                  resourceId={resourceId}
                                  BackToModal={EditNotificationsModal}
                                />,
                              );
                            }}
                            className="flex items-center space-x-2 group"
                          >
                            <Icon className="mr-2 h-4 w-4 shrink-0 fill-current text-cadet-blue-500 group-hover:text-dodger-blue-300" />
                            {displayName}
                          </PopoverItem>
                        );
                      })}
                    </>
                  )}
                </StyledPopoverPanel>,
                document.querySelector(`#${ROOT_ELEMENT_ID}`),
              )
            : null}
        </>
      )}
    </Popover>
  );
}
