import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Spinner, Autocomplete } from '@crazyegginc/hatch';

import { useModal, useMutation, useNotifications, useSelectedSite, useAuthContext } from '/src/hooks';
import { createNotificationMutation, editNotificationMutation } from '../../mutations';
import { channelsListQuery } from '../../queries';
import { teamMembersQuery } from '/src/features/_global/queries';
import { DeleteWarningModal } from './DeleteWarningModal';

import { Modal } from '/src/contexts/modal';
import { RESOURCE_META, NOTIFICATION_TYPES } from '../../constants';

const validationSchema = yup.object().shape({
  email: yup.string().required('Please provide an email.').email('Please provide a valid email.'),
});

export function EmailModal({ notification, resourceType, resourceId, BackToModal, closeAfterAction = false }) {
  const modal = useModal();
  const notifications = useNotifications();
  const { selectedSite } = useSelectedSite();
  const { currentUser } = useAuthContext();
  const siteId = notification?.siteId ?? selectedSite.id;
  const editing = !!notification;

  const createMutation = useMutation(createNotificationMutation);
  const editMutation = useMutation(editNotificationMutation);
  const mutation = editing ? editMutation : createMutation;

  const { action, actionText } = RESOURCE_META[resourceType];

  const { data, isFetching } = useQuery(channelsListQuery({ siteId, type: NOTIFICATION_TYPES.EMAIL }));
  const { data: teamMembersData, isFetching: isFetchingTeamMembers } = useQuery(teamMembersQuery({ siteId }));

  const existingEmails = useMemo(
    () =>
      [
        ...new Set([
          ...(data?.channelsList.map((c) => c.destination) ?? []),
          ...(teamMembersData?.teamMembers.map((t) => t.email) ?? []),
        ]),
      ]
        .map((x) => ({ value: x }))
        .sort((a, b) => (a.value > b.value ? 1 : a.value < b.value ? -1 : 0)),
    [data, teamMembersData],
  );

  return (
    <Modal>
      <Modal.Title>{editing ? 'Edit Email Notification' : 'Get Notified via Email'}</Modal.Title>

      <div className="text-body-2 mb-3.75">
        {editing
          ? `You’re currently being notified via email ${notification.channel.destination} when ${actionText}.`
          : `We’ll send you an email when ${actionText}. Which email address do you want to send these notifications to?`}
      </div>

      <Formik
        initialValues={{
          email: editing ? notification.channel.destination : currentUser.email,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const existingChannel = data?.channelsList.find((c) => c.destination === values.email);
          const vars = editing
            ? {
                notificationId: notification.id,
                siteId: notification.siteId,
                channelId: existingChannel ? existingChannel.id : null,
                channelDestination: existingChannel ? null : values.email,
              }
            : {
                action,
                channelId: existingChannel ? existingChannel.id : null,
                channelDestination: existingChannel ? null : values.email,
                channelType: NOTIFICATION_TYPES.EMAIL,
                resource: resourceType,
                resourceId: String(resourceId),
              };
          mutation.mutate(vars, {
            onError: (error) => {
              const isDuplicateError = error.graphQLErrors?.[0]?.originalError?.message?.match(
                /notification: has already been taken/,
              );

              notifications.error({
                title: `Failed to ${editing ? 'edit' : 'create'} notification.`,
                content: isDuplicateError ? 'This notification already exists.' : 'Please try again.',
                timeout: 4000,
                context: { error },
                skipHoneybadger: isDuplicateError ? true : false,
              });
            },
            onSuccess: () => {
              notifications.success({
                content: `Notification ${editing ? 'edited' : 'created'} successfully.`,
                timeout: 3000,
              });
              if (closeAfterAction) {
                modal.close();
              } else {
                modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />);
              }
            },
          });
        }}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
          return (
            <Form className="w-full">
              <Autocomplete
                label="Email address:"
                labelClassName="font-semibold"
                options={existingEmails}
                value={values.email}
                onChange={(value) => setFieldValue('email', value)}
                onBlur={() => setFieldTouched('email', true)}
                allowCustom={true}
                renderInPortal={true}
                popperClassName="!z-[100000]"
                error={touched.email && errors.email ? errors.email : null}
                loading={isFetching || isFetchingTeamMembers}
              />

              <Modal.Actions className="justify-between">
                <div className="flex items-center space-x-2.5">
                  <Button disabled={mutation.isLoading} type="submit">
                    {mutation.isLoading && (
                      <span className="mr-2.5">
                        <Spinner />
                      </span>
                    )}
                    {editing && (mutation.isLoading ? 'Saving...' : 'Save')}
                    {!editing && (mutation.isLoading ? 'Creating...' : 'Create notification')}
                  </Button>

                  <Button
                    variant="cancel"
                    onClick={() =>
                      BackToModal
                        ? modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />)
                        : modal.close()
                    }
                    className="disabled:bg-transparent"
                  >
                    {editing ? 'Cancel' : 'Back'}
                  </Button>
                </div>
                {editing ? (
                  <Button
                    variant="warning"
                    onClick={() => {
                      modal.show(
                        <DeleteWarningModal
                          id={notification.id}
                          resourceType={notification.resource}
                          resourceId={notification.resourceId}
                          BackToModal={BackToModal}
                        />,
                      );
                    }}
                  >
                    Delete notification
                  </Button>
                ) : null}
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
