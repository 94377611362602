import { WarningModal } from '../modals/WarningModal';
import { inflect } from '/src/utils/string';

import { useMutation, usePermissions, useModal } from '/src/hooks';
import { deleteNotificationMutation } from '/src/features/notifications/mutations';
import { notificationToggleEnabledMutation } from '/src/features/notifications/mutations';

import { ActionIcon } from '/src/components/bulk-action/ActionIcon';
import { ActionText } from '/src/components/bulk-action/ActionText';

import { FEATURES } from '/src/features/_global/constants';
import { NOTIFICATION_TYPES } from '/src/features/notifications/constants';

import { ReactComponent as RemoveIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';

export function NotificationsBulkActions({ onActionStart, onSuccess, onError, selectedIds, currentSelection }) {
  const modal = useModal();
  const permissions = usePermissions();
  const deleteNotifications = useMutation(deleteNotificationMutation);
  const toggleNotifications = useMutation(notificationToggleEnabledMutation);

  const hasZapier = currentSelection.some((notification) => notification.channel.type === NOTIFICATION_TYPES.ZAPIER);
  const deletableIds = currentSelection
    .filter((notification) => notification.channel.type !== NOTIFICATION_TYPES.ZAPIER)
    .map((notification) => notification.id);

  const canEdit = permissions.can('edit', FEATURES.NOTIFICATIONS).allowed;

  return (
    <>
      {canEdit && deletableIds.length > 0 && (
        <ActionIcon
          img={RemoveIcon}
          alt="Delete selected"
          tooltip="Delete"
          onClick={() => {
            modal.show(
              <WarningModal
                onConfirm={() => {
                  onActionStart();
                  deleteNotifications.mutate(
                    {
                      ids: [...deletableIds],
                    },
                    {
                      onError: (error) => onError({ error, name: 'Delete notifications' }),
                      onSuccess: (data) => {
                        const affected = data.notificationDelete.affected;
                        onSuccess({
                          deselectAll: true,
                          message: `${affected.length} ${inflect('notification', affected.length)} deleted successfully.`,
                        });
                      },
                    },
                  );
                }}
                title={
                  hasZapier
                    ? 'Cannot delete Zapier notification'
                    : `Delete ${deletableIds.length} ${inflect('notification', deletableIds.length)}?`
                }
                body={
                  hasZapier
                    ? `Zapier notifications can only be deleted by logging into Zapier directly. Continue deleting ${deletableIds.length} other ${inflect('notification', deletableIds.length)}?`
                    : `Are you sure you want to delete ${deletableIds.length} ${inflect('notification', deletableIds.length)}?`
                }
                button={`Yes, delete ${inflect('notification', deletableIds.length)}`}
              />,
            );
          }}
        />
      )}
      {canEdit && (
        <>
          <ActionText
            text="Activate"
            onClick={() => {
              onActionStart();
              toggleNotifications.mutate(
                { ids: [...selectedIds], enabled: true },
                {
                  onError: (error) => onError({ error, name: 'Activate notifications' }),
                  onSuccess: (data) => {
                    const affected = data.notificationToggleEnabled.affected;
                    onSuccess({
                      message: `${affected.length} ${inflect('notification', affected.length)} ${
                        affected.length > 1 ? 'have' : 'has'
                      } been activated.`,
                    });
                  },
                },
              );
            }}
          />
          <ActionText
            text="Deactivate"
            onClick={() => {
              onActionStart();
              toggleNotifications.mutate(
                { ids: [...selectedIds], enabled: false },
                {
                  onError: (error) => onError({ error, name: 'Deactivate notifications' }),
                  onSuccess: (data) => {
                    const affected = data.notificationToggleEnabled.affected;
                    onSuccess({
                      message: `${affected.length} ${inflect('notification', affected.length)} ${
                        affected.length > 1 ? 'have' : 'has'
                      } been deactivated.`,
                    });
                  },
                },
              );
            }}
          />
        </>
      )}
    </>
  );
}
