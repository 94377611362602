import classNames from 'classnames';
import { Button, Popover, StyledPopoverPanel, PopoverItem, Indicator } from '@crazyegginc/hatch';

import { useFilter } from '../notifications-filter-context';
import { SearchInput } from '/src/components/search-input';
import { NOTIFICATION_TYPES, NOTIFICATIONS_META } from '../constants';

const SHOW_ALL = 'Show All';

export function NotificationsFilters() {
  const { search, setSearch } = useFilter();

  return (
    <div className="flex w-full items-center justify-end space-x-3.75 pb-3.75">
      <NotificationsStatusDropdown />
      <SearchInput
        initialValue={search}
        search={setSearch}
        placeholder="Search"
        className=" !border-solitude-500 !bg-solitude-500 !font-semibold !placeholder-dodger-blue-500/50"
      />
    </div>
  );
}

function NotificationsStatusDropdown() {
  const { type, setTypeFilter } = useFilter();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant="secondary"
            size="lg"
            className={classNames('w-28', { '!border-dodger-blue-500': open })}
            data-testid="channel-type-filter"
          >
            {NOTIFICATIONS_META[type]?.displayName ?? SHOW_ALL}
            <Indicator type="dropdown" className="absolute right-4 top-1/2 -translate-y-1/2" />
          </Popover.Button>

          <StyledPopoverPanel align="center" className="!w-[120px]">
            {({ close }) => (
              <>
                <PopoverItem
                  autoWidth
                  onClick={() => {
                    setTypeFilter(null);
                    close();
                  }}
                >
                  {SHOW_ALL}
                </PopoverItem>
                {Object.values(NOTIFICATION_TYPES).map((notificationType) => {
                  const { Icon, displayName } = NOTIFICATIONS_META[notificationType];
                  return (
                    <PopoverItem
                      key={notificationType}
                      onClick={() => {
                        setTypeFilter(notificationType);
                        close();
                      }}
                      className="flex items-center space-x-2 group"
                    >
                      <Icon className="mr-2 h-4 w-4 shrink-0 fill-current text-cadet-blue-500 group-hover:text-dodger-blue-300" />
                      {displayName}
                    </PopoverItem>
                  );
                })}
              </>
            )}
          </StyledPopoverPanel>
        </>
      )}
    </Popover>
  );
}
