import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { RESOURCE_META } from '../../constants';

import { ReactComponent as OpenIcon } from '@crazyegginc/hatch/dist/images/icon-open-url-outline.svg';

export function ZapierModal({ notification, resourceType, resourceId, BackToModal }) {
  const modal = useModal();
  const { actionText } = RESOURCE_META[resourceType];
  const editing = !!notification;

  return (
    <Modal>
      <Modal.Title>{editing ? 'Edit Zapier Notification' : 'Get Notified via Zapier'}</Modal.Title>

      <div className="text-body-2">
        {editing
          ? `Data is being sent to Zapier when ${actionText}. Log in to Zapier to edit or delete this Notification.`
          : `We’ll send data to Zapier when ${actionText}. Log in to Zapier to create your Notification.`}
      </div>

      <Modal.Actions>
        <Button component="a" href="https://zapier.com/app/login" target="_blank" rel="noopener noreferrer">
          Log in to Zapier
          <OpenIcon className="ml-2.5 h-3 w-3 fill-current" />
        </Button>

        <Button
          variant="cancel"
          onClick={() =>
            BackToModal
              ? modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />)
              : modal.close()
          }
          className="disabled:bg-transparent"
        >
          {editing ? 'Cancel' : 'Back'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
