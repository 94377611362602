import { Link } from 'react-router-dom';
import { Button } from '@crazyegginc/hatch';

import { Paywall } from '/src/components/Paywall';

import YellowWarningPNG from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.png';
import YellowWarningWebP from '@crazyegginc/hatch/dist/images/illustration-warning-yellow.webp';

export function AbTestNotFoundWall() {
  return (
    <Paywall>
      <Paywall.Image webp={YellowWarningWebP} fallback={YellowWarningPNG} width="204px" height="162px" />
      <Paywall.Title>Oops, we couldn&apos;t find that A/B test!</Paywall.Title>

      <Button className="mt-5" component={Link} to="/ab-tests">
        Take me back to the dashboard
      </Button>
    </Paywall>
  );
}
