import { SlackModal } from './components/modals/SlackModal';
import { EmailModal } from './components//modals/EmailModal';
import { APIModal } from './components//modals/APIModal';
import { ZapierModal } from './components//modals/ZapierModal';

import { ReactComponent as GoalIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as SurveyIcon } from '@crazyegginc/hatch/dist/images/icon-survey-filled.svg';
import { ReactComponent as SlackIcon } from '@crazyegginc/hatch/dist/images/logo-slack.svg';
import { ReactComponent as ZapierIcon } from '@crazyegginc/hatch/dist/images/logo-zapier-integrations.svg';
import { ReactComponent as MailIcon } from '@crazyegginc/hatch/dist/images/icon-mail.svg';
import { ReactComponent as CodeIcon } from '@crazyegginc/hatch/dist/images/icon-code-outline.svg';

export const NOTIFICATION_TYPES = {
  SLACK: 'SLACK',
  EMAIL: 'EMAIL',
  API: 'API',
  ZAPIER: 'ZAPIER',
};

export const NOTIFICATIONS_META = {
  [NOTIFICATION_TYPES.SLACK]: {
    Icon: SlackIcon,
    displayName: 'Slack',
    Modal: SlackModal,
  },
  [NOTIFICATION_TYPES.EMAIL]: {
    Icon: MailIcon,
    displayName: 'Email',
    Modal: EmailModal,
  },
  [NOTIFICATION_TYPES.API]: {
    Icon: CodeIcon,
    displayName: 'API',
    Modal: APIModal,
  },
  [NOTIFICATION_TYPES.ZAPIER]: {
    Icon: ZapierIcon,
    displayName: 'Zapier',
    Modal: ZapierModal,
  },
};

export const NOTIFICATION_RESOURCE_TYPES = {
  SURVEY: 'SURVEY',
  GOAL: 'GOAL',
};

export const RESOURCE_META = {
  [NOTIFICATION_RESOURCE_TYPES.SURVEY]: {
    Icon: SurveyIcon,
    displayName: 'Survey',
    link: (id) => `/addons/${id}`,
    action: 'RESPONDED',
    actionText: 'this survey receives a new response',
  },
  [NOTIFICATION_RESOURCE_TYPES.GOAL]: {
    Icon: GoalIcon,
    displayName: 'Goal',
    link: (id) => `/goals/${id}`,
    action: 'CONVERTED',
    actionText: 'this goal receives a new conversion',
  },
};

export const NOTIFICATIONS_ORDER = {
  CREATED_AT: 'CREATED_AT',
  DESTINATION: 'DESTINATION',
  RESOURCE_NAME: 'RESOURCE_NAME',
};
