import { DashHeader } from '/src/components/headers/DashHeader';
import { DashboardPage } from '/src/components/Page';
import { getFeatureName } from '/src/features/_global/utils';
import { useAuthContext } from '/src/hooks';

import { TrialExpiredPaywall } from '/src/features/_global/paywalls/TrialExpiredPaywall';
import { SubscriptionCancelledPaywall } from '/src/features/_global/paywalls/SubscriptionCancelledPaywall';
import { SubscriptionExpiredPaywall } from '/src/features/_global/paywalls/SubscriptionExpiredPaywall';
import { TrialCCFailedPaywall } from '/src/features/_global/paywalls/TrialCCFailedPaywall';

import { LiveUpgradePaywall } from '/src/features/live-activity/components/paywalls/LiveUpgradePaywall';
import { SnapshotsUpgradePaywall } from '/src/features/snapshots/paywalls/SnapshotsUpgradePaywall';
import { RecordingsUpgradePaywall } from '/src/features/recordings/components/dashboard/paywalls/RecordingsUpgradePaywall';
import { GoalsUpgradePaywall } from '/src/features/goals/paywalls/GoalsUpgradeWall';
import { ABTestingUpgradePaywall } from '/src/features/ab-testing/components/paywalls/ABTestingUpgradePaywall';
import { TAUpgradePaywall } from '/src/features/traffic-analysis/components/paywalls/TAUpgradePaywall';
import { ErrorsUpgradePaywall } from '/src/features/errors/components/paywalls/ErrorsUpgradePaywall';
import { AddonsUpgradePaywall } from '/src/features/addons/paywalls/AddonsUpgradeWall';
import { SurveysUpgradePaywall } from '/src/features/addons/paywalls/SurveysUpgradeWall';
import { CTAsUpgradePaywall } from '/src/features/addons/paywalls/CTAsUpgradeWall';
import { CAPABILITY_REASONS, FEATURES, SUBSCRIPTION_STATES, PLAN_TYPES } from '/src/features/_global/constants';

export function DashboardPaywall({ feature, reason, mini, contentOnly }) {
  const { subscription } = useAuthContext();

  function renderPaywall() {
    switch (reason) {
      case CAPABILITY_REASONS.SUBSCRIPTION_ISSUE:
        {
          switch (subscription.state) {
            case SUBSCRIPTION_STATES.EXPIRED:
              {
                switch (subscription.plan.type) {
                  case PLAN_TYPES.TRIAL:
                    return <TrialExpiredPaywall />;
                  case PLAN_TYPES.PAID:
                    return <SubscriptionExpiredPaywall />;
                }
              }
              break;
            case SUBSCRIPTION_STATES.CANCELED:
              return <SubscriptionCancelledPaywall />;
            case SUBSCRIPTION_STATES.TRIAL_CC_FAILED:
              return <TrialCCFailedPaywall />;
            case SUBSCRIPTION_STATES.INACTIVE:
              // TODO for Zombie Freemium
              return;
          }
        }
        break;
      case CAPABILITY_REASONS.UPGRADE: {
        switch (feature) {
          case FEATURES.LIVE_ACTIVITY:
            return <LiveUpgradePaywall mini={mini} />;
          case FEATURES.SNAPSHOTS:
            return <SnapshotsUpgradePaywall mini={mini} />;
          case FEATURES.RECORDINGS:
            return <RecordingsUpgradePaywall mini={mini} />;
          case FEATURES.GOALS:
            return <GoalsUpgradePaywall mini={mini} />;
          case FEATURES.AB_TESTING:
            return <ABTestingUpgradePaywall mini={mini} />;
          case FEATURES.TRAFFIC_ANALYSIS:
            return <TAUpgradePaywall mini={mini} />;
          case FEATURES.ERRORS_TRACKING:
            return <ErrorsUpgradePaywall mini={mini} />;
          case FEATURES.ADDONS:
            return <AddonsUpgradePaywall mini={mini} />;
          case FEATURES.SURVEYS:
            return <SurveysUpgradePaywall mini={mini} />;
          case FEATURES.CTAS:
            return <CTAsUpgradePaywall mini={mini} />;
        }
      }
    }
    return null;
  }

  if (mini || contentOnly) return renderPaywall();

  return (
    <DashboardPage>
      <DashHeader title={getFeatureName(feature)} />
      <div style={{ padding: '0 25%' }}>{renderPaywall()}</div>
    </DashboardPage>
  );
}
