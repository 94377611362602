import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const globalFeatureKeys = createQueryKeys('globalFeature', {
  userData: null,
  adminAnnouncement: null,
  me: null,
  capabilities: null,
  subscription: null,
  features: null,
  sessionInfo: null,
  sharedResource: ({ sharingCode }) => [{ sharingCode }],
  teamMembers: ({ siteId }) => [{ siteId }],
  countries: null,
});

const STALE_TIME = 5 * 60;

export const SITES_QUERY = gql`
  query UserSites {
    sites {
      __typename
      id
      name
      installed
      lastData
      recordingSettings {
        blockedUrlMatchingRules
        endSessionOnBlockedUrl
        maskElements
        pageTargetingEnabled
        samplingType
        samplingValue
        urlMatchingRules
      }
      sessionRecording
      lastData
      active
      estimatedMaxRecordingsDay
      estimatedVisitsDay
      recordingRatio
      metadata
    }
  }
`;

export const SINGLE_SITE_QUERY = gql`
  query SingleSite($id: Int!) {
    site(id: $id) {
      id
      name
    }
  }
`;

export const siteFeatureKeys = createQueryKeys('sites', {
  site: ({ id }) => [{ id }],
});

export const siteQuery = ({ id }) => ({
  ...siteFeatureKeys.site({ id }),
  useErrorBoundary: false,
  refetchOnWindowFocus: true,
  meta: {
    query: gql`
      query Site($id: Int!) {
        site(id: $id) {
          id
          sessionRecording
        }
      }
    `,
  },
});

export const userDataQuery = () => ({
  ...globalFeatureKeys.userData,
  staleTime: 0,
  meta: {
    query: gql`
      query UserData {
        me {
          userData {
            key
            value
          }
        }
      }
    `,
  },
});

export const adminAnnouncementQuery = () => ({
  ...globalFeatureKeys.adminAnnouncement,
  refetchInterval: 60000,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query AdminAnnouncement {
        announcement {
          enabled
          severity
          htmlContent
          updatedAt
        }
      }
    `,
  },
});

export const meQuery = () => ({
  ...globalFeatureKeys.me,
  useErrorBoundary: false,
  refetchOnWindowFocus: true,
  staleTime: STALE_TIME,
  meta: {
    query: gql`
      query Me {
        me {
          userId
          guestId
          email
          avatarUrl
          flags
          name
          language
          accounts {
            id
            isCurrent
            isAccountHolder
            isOwner
            isManager
            isReadOnly
            isRestricted
            restrictedSitesIds
            name
            ownerAvatarUrl
            ownerEmail
            ownerName
            paddedId
            scriptUrl
            scriptVersion
            sites
            isShopifyUser
            shopifyDomain
          }
          subscriptions {
            snapshots {
              id
              nextPaymentPlan {
                hasPrioritySupport
                id
                isEnterprise
                isFree
                isFreemium
                isTrial
                name
                planGroup
                planType
              }
              paymentPlan {
                hasPrioritySupport
                id
                isFree
                isFreemium
                isEnterprise
                isTrial
                name
                planType
                planGroup
              }
              amount
              startedOn
              cancelledOn
              expiredOn
              renewOn
              isExpired
              isCancelled
              isCancelling
              isExpiring
              isActive
              storageDuration
              interval
              intervalType
              nextAmount
              nextInterval
              nextIntervalType
            }
            recordings {
              id
              paymentPlan {
                id
                isFree
                isEnterprise
                isTrial
                name
                planType
                planGroup
              }
              startedOn
              cancelledOn
              expiredOn
              renewOn
              isExpired
              isCancelled
              isCancelling
              isExpiring
              isActive
              storageDuration
              intervalType
            }
          }
          userData {
            key
            value
          }
          userNotifications {
            accountLimits
            receipt
            weeklyUpdate
          }
          features
          surveys
          settings {
            autoIdentity
            dnt
            defaultCurrency
            explicitAgent
            useSecureCookies
          }
        }
      }
    `,
  },
});

export const sessionInfoQuery = () => ({
  ...globalFeatureKeys.sessionInfo,
  useErrorBoundary: false,
  meta: {
    query: gql`
      query SessionInfo {
        sessionInfo {
          isAuthenticated
          hasSharedItems
          isImpersonated
          isImpersonatedByReadOnly
        }
      }
    `,
  },
});

export const sharedResourceQuery = ({ sharingCode }) => ({
  ...globalFeatureKeys.sharedResource({ sharingCode }),
  useErrorBoundary: false,
  meta: {
    query: gql`
      query SharedResource($sharingCode: ID!) {
        sharedResource(sharingCode: $sharingCode) {
          type
          resource {
            ... on SharedResourceRedirect {
              url
            }
            ... on SharedResourceData {
              resourceType
              resourceId
              userId
              siteId
              siteName
              authToken
            }
          }
        }
      }
    `,
  },
});

export const teamMembersQuery = ({ siteId }) => ({
  ...globalFeatureKeys.teamMembers({ siteId }),
  meta: {
    query: gql`
      query TeamMembers($siteId: Int) {
        teamMembers(siteId: $siteId) {
          email
          id
          name
        }
      }
    `,
  },
});

const permissionValueFragment = gql`
  fragment permissionValue on CapabilityPermissionValue {
    allowed
    reason
  }
`;

const quotaFragment = gql`
  fragment quota on CapabilityQuota {
    current
    limit
  }
`;

const basicPermissionsFragment = gql`
  fragment basicPermissions on CapabilityPermission {
    create {
      ...permissionValue
    }
    edit {
      ...permissionValue
    }
    nav {
      ...permissionValue
    }
    view {
      ...permissionValue
    }
  }
  ${permissionValueFragment}
`;

export const capabilitiesQuery = () => ({
  ...globalFeatureKeys.capabilities,
  useErrorBoundary: false,
  refetchOnWindowFocus: true,
  staleTime: STALE_TIME,
  meta: {
    query: gql`
      query Capabilities {
        capabilities {
          abTesting {
            permissions {
              ...basicPermissions
            }
          }
          account {
            quotas {
              monthlyPageViews {
                ...quota
              }
            }
          }
          addons {
            permissions {
              manageTemplates {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
            }
          }
          api {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
            }
          }
          auditTrail {
            permissions {
              nav {
                ...permissionValue
              }
              view {
                ...permissionValue
              }
            }
          }
          billing {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
              navStatus {
                ...permissionValue
              }
            }
          }
          commenting {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
            }
          }
          ctas {
            permissions {
              ...basicPermissions
              removeBranding {
                ...permissionValue
              }
            }
          }
          errors {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
              view {
                ...permissionValue
              }
            }
          }
          goals {
            permissions {
              ...basicPermissions
            }
          }
          help {
            permissions {
              nav {
                ...permissionValue
              }
            }
          }
          installation {
            permissions {
              nav {
                ...permissionValue
              }
              view {
                ...permissionValue
              }
            }
          }
          integrations {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
            }
          }
          liveActivity {
            permissions {
              nav {
                ...permissionValue
              }
              view {
                ...permissionValue
              }
            }
          }
          crazyEggEmailSettings {
            permissions {
              nav {
                ...permissionValue
              }
              edit {
                ...permissionValue
              }
            }
          }
          notifications {
            permissions {
              nav {
                ...permissionValue
              }
              edit {
                ...permissionValue
              }
            }
          }
          recordings {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
              view {
                ...permissionValue
              }
              settings {
                ...permissionValue
              }
            }
            quotas {
              monthlyRecordings {
                ...quota
              }
              storageDuration {
                ...quota
              }
            }
          }
          siteSettings {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
              manageSites {
                ...permissionValue
              }
            }
            quotas {
              totalSites {
                ...quota
              }
            }
          }
          snapshots {
            permissions {
              ...basicPermissions
            }
            quotas {
              totalSnapshots {
                ...quota
              }
              visitsPerSnapshot {
                limit
              }
            }
          }
          sso {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
            }
          }
          surveys {
            permissions {
              ...basicPermissions
              removeBranding {
                ...permissionValue
              }
            }
          }
          teamSettings {
            permissions {
              edit {
                ...permissionValue
              }
              nav {
                ...permissionValue
              }
            }
          }
          trackingOptions {
            permissions {
              nav {
                ...permissionValue
              }
              edit {
                ...permissionValue
              }
            }
          }
          trafficAnalysis {
            permissions {
              nav {
                ...permissionValue
              }
              view {
                ...permissionValue
              }
            }
          }
        }
      }
      ${basicPermissionsFragment}
      ${quotaFragment}
      ${permissionValueFragment}
    `,
  },
});

export const subscriptionQuery = () => ({
  ...globalFeatureKeys.subscription,
  useErrorBoundary: false,
  refetchOnWindowFocus: true,
  staleTime: STALE_TIME,
  meta: {
    query: gql`
      query Subscription {
        subscription {
          active
          endDate
          plan {
            name
            period
            platform
            price
            type
          }
          state
        }
      }
    `,
  },
});

export const countriesQuery = () => ({
  ...globalFeatureKeys.countries,
  staleTime: 86400,
  meta: {
    query: gql`
      query Countries {
        countries
      }
    `,
  },
});
