import { useMemo, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Legend,
  defaults,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import { atom, useAtom } from 'jotai';
import { fromUnixTime, startOfDay, format, isSameDay, isBefore } from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useAuthContext, useSelection } from '/src/hooks';
import { MouseLine } from '/src/chartjs-plugins/MouseLine';
import { capitalize } from '@crazyegginc/hatch';
import { abTestSelectedDatesAtom } from '../pages/show';
import { abTestDistributionQuery } from '../queries';
import { formatCurrency } from '/src/utils';
import { getTimeZoneOffset } from '/src/utils/date';

defaults.font.family = 'Proxima Nova, Lucida Grande, Tahoma, Verdana, Arial, sans-serif';

ChartJS.register(MouseLine, Legend, LinearScale, CategoryScale, PointElement, LineElement, Filler);

export const abTestDistributionDatasetAtom = atom('conversions');

const timezoneOffset = getTimeZoneOffset();

function getDatasetKey(dataset) {
  switch (dataset) {
    case 'conversions':
      return 'totalConversions';
    case 'conversion_rate':
      return 'avgConversionRate';
    case 'visitors':
      return 'totalVisitors';
    case 'value':
      return 'totalValue';
  }
}

function getYAxisLabel(dataset, currency) {
  if (dataset === 'value') {
    return `Value (${currency})`;
  }
  if (dataset === 'conversion_rate') {
    return 'Conversion Rate (%)';
  }
  return capitalize(dataset.replace('_', ' '));
}

export function DistributionChart({ abTest, goal }) {
  const { currentSelection: selectedVariants } = useSelection();
  const { currentUser } = useAuthContext();
  const defaultCurrency = currentUser?.settings?.defaultCurrency ?? 'USD';

  const { id } = useParams();

  const [dataset] = useAtom(abTestDistributionDatasetAtom);
  const [selection] = useAtom(abTestSelectedDatesAtom);

  const { data } = useQuery({
    ...abTestDistributionQuery({
      id: Number(id),
      goalId: goal?.id ? Number(goal.id) : null,
      presetDate: selection.special,
      startDate: selection.special ? undefined : selection.startDate + timezoneOffset,
      endDate: selection.special ? undefined : selection.endDate + timezoneOffset,
    }),
    enabled: !!id && !!goal?.id,
    keepPreviousData: true,
    cacheTime: 0,
    networkMode: 'always',
  });

  const weeklyChart = data?.abTestDistribution?.[0]?.granularity === 'WEEK';

  const days = useMemo(() => {
    if (!(data?.abTestDistribution ?? []).length) return [];

    const weekChart = data?.abTestDistribution?.[0]?.granularity === 'WEEK';

    if (!weekChart) {
      return data.abTestDistribution.map((d) => fromUnixTime(d.date));
    } else {
      return data.abTestDistribution.map((d) => [fromUnixTime(d.startDate), fromUnixTime(d.endDate)]);
    }
  }, [data]);

  const chartLabels = useMemo(() => {
    if (!days) return null;
    if (days && weeklyChart) {
      return days.map((interval) => `${format(interval[0], 'MMM dd, yyyy')} - ${format(interval[1], 'MMM dd, yyyy')}`);
    }
    return days.map((day) => format(day, 'MMM dd, yyyy'));
  }, [days, weeklyChart]);

  const simpleLabels = useMemo(() => {
    if (!days) return null;
    if (days && weeklyChart) {
      return days.map((interval) => `${format(interval[0], 'MMM dd')} - ${format(interval[1], 'MMM dd')}`);
    }
    return days.map((day) => format(day, 'MMM dd'));
  }, [days, weeklyChart]);

  const chartValuesForVariant = useCallback(
    (variantId) => {
      const datasetMapping = getDatasetKey(dataset);
      const distData = data?.abTestDistribution ?? [];

      if (!days || !goal) return null;

      const dataStartDate = startOfDay(fromUnixTime(goal.createdAt));

      return days.map((date) => {
        const day = distData.find((item) => {
          if (Array.isArray(date)) {
            return isSameDay(fromUnixTime(item.startDate), date[0]);
          } else {
            return isSameDay(fromUnixTime(item.date), date);
          }
        });
        const dataPoint =
          day?.distribution?.find?.((d) => d.variantId === variantId)?.[datasetMapping] ??
          (isBefore(date, dataStartDate) ? null : 0);

        if (datasetMapping === getDatasetKey('value')) {
          return dataPoint / 10000;
        }

        return dataPoint;
      });
    },
    [days, goal, data, dataset],
  );

  const distributionData = useMemo(
    () => ({
      labels: chartLabels,
      datasets: selectedVariants.map((variant) => {
        const selectedIndex = selectedVariants.findIndex((v) => v.id === variant.id);
        const variantColor = getVariantColor(selectedIndex);

        return {
          label: capitalize(variant.variantName),
          data: chartValuesForVariant(variant.id),
          fill: true,
          backgroundColor: (ctx) => {
            const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 235);
            gradient.addColorStop(0, `${variantColor}15`);
            gradient.addColorStop(1, `${variantColor}00`);
            return gradient;
          },
          borderColor: variantColor,
          pointBackgroundColor: 'white',
          pointBorderColor: variantColor,
          pointBorderWidth: 3,
          pointRadius: 5,
        };
      }),
    }),
    [chartLabels, chartValuesForVariant, selectedVariants],
  );

  const activeDatasetFormatter = useCallback(
    (value) => {
      if (dataset === 'conversion_rate') {
        return `${parseFloat(value).toFixed(1)}%`;
      }
      if (dataset === 'value') {
        return formatCurrency(Number(value), defaultCurrency);
      }
      return value;
    },
    [dataset, defaultCurrency],
  );

  const options = useMemo(
    () => ({
      interaction: {
        mode: 'index',
        intersect: false,
      },
      maintainAspectRatio: false,
      spanGaps: true,
      scales: {
        y: {
          suggestedMin: 0,
          suggestedMax: 1,
          ticks: {
            color: 'rgb(104, 118, 141)',
            stepSize: 10,
            padding: 10,
          },
          grid: {
            color: 'rgb(225, 230, 239)',
            lineWidth: 1,
          },
          border: {
            display: false,
            dash: [6, 3],
          },
        },
        x: {
          ticks: {
            color: 'rgb(104, 118, 141)',
            padding: 15,
            callback: function (value) {
              return simpleLabels[value];
            },
            autoSkip: false,
            minRotation: weeklyChart ? 10 : undefined,
            maxRotation: weeklyChart ? 10 : undefined,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (ctx) => externalTooltipHandler(ctx, activeDatasetFormatter),
        },
        legend: {
          labels: false,
        },
        mouseline: {
          enabled: true,
        },
      },
    }),
    [activeDatasetFormatter, simpleLabels, weeklyChart],
  );

  if (!abTest || !goal) return null;

  return (
    <div className="w-full">
      <div
        className="flex w-full flex-col rounded border border-mystic-500 bg-white"
        style={{
          boxShadow: '0 4px 6px 0 rgba(7,31,49,0.07)',
        }}
      >
        {dataset === 'value' && !abTest?.selectedGoal?.hasValue ? (
          <div className="flex flex-row p-8">
            <div className="flex h-[300px] w-5 w-full flex-col items-center justify-center">
              <span className="text-body-5">A value for this Goal has not been set or detected.</span>
              <Link to={`/goals/${id}/edit-value`} className="text-link mt-2">
                Set Goal value
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-row p-8">
            <div className="flex h-[300px] w-5 items-center justify-center">
              <span className="text-caption -rotate-90 transform whitespace-nowrap">
                {getYAxisLabel(dataset, defaultCurrency)}
              </span>
            </div>
            {/* 
            NOTE: No need skeleton loader since GRAPHJS will show only the grids
          */}
            <div className="relative flex h-[300px] min-w-[500px] flex-1">
              <Line data={distributionData} options={options} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function getVariantColor(index) {
  if (index === -1) {
    return null;
  }

  const colorMatrix = [
    '#99cff5',
    '#ba89f5',
    '#8abc00',
    '#ff8fa7',
    '#ffd155',
    '#0086e6',
    '#8f6bba',
    '#8EDEC5',
    '#FFAF8F',
    '#FF7679',
  ];

  return colorMatrix[index] ?? '#A5ACBC';
}

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, formatter = null) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');

    if (tooltip.title && tooltip.title.length > 0) {
      const th = document.createElement('th');
      const titleText = document.createTextNode(tooltip.title[0]);
      th.appendChild(titleText);
      th.colSpan = 2;
      tableHead.appendChild(th);
      tableHead.style.textAlign = 'center';
    }

    const tableBody = document.createElement('tbody');

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.borderColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderRadius = '50%';
      span.style.borderWidth = '2px';
      span.style.marginRight = '15px';
      span.style.height = '6px';
      span.style.width = '6px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td1 = document.createElement('td');
      td1.style.borderWidth = 0;
      td1.style.verticalAlign = 'middle';

      const td2 = document.createElement('td');
      td2.style.borderWidth = 0;
      td2.style.paddingLeft = '15px';
      td2.style.fontWeight = 'bold';

      const [variantName, chartValue] = body[0].split(':').map((a) => a.trim());

      const vName = document.createTextNode(variantName);
      const cValue = document.createTextNode(formatter ? formatter(chartValue) : chartValue);

      td1.appendChild(span);
      td1.appendChild(vName);
      td2.appendChild(cValue);
      tr.appendChild(td1);
      tr.appendChild(td2);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY, offsetWidth: canvasWidth } = chart.canvas;

  // Calculate the initial tooltip position
  let left = positionX + tooltip.caretX;
  let top = positionY + tooltip.caretY - tooltipEl.offsetHeight - 2;

  // Adjust if the tooltip goes beyond the canvas on the right side
  if (left + tooltipEl.offsetWidth > positionX + canvasWidth) {
    left = positionX + canvasWidth - tooltipEl.offsetWidth - 10;
  }

  // Adjust if the tooltip goes beyond the canvas on the left side
  if (left < positionX) {
    left = positionX + 10; // 10px padding from the edge
  }

  // Adjust if the tooltip goes beyond the canvas on the top
  if (top < positionY) {
    top = positionY + tooltip.caretY + 10; // 10px padding below the cursor if too high
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${left}px`;
  tooltipEl.style.top = `${top - 6}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  tooltipEl.classList.add('tooltip');
};
