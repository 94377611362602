import { useMemo, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useSelectedSite, useModal } from '/src/hooks';

import { Modal } from '/src/contexts/modal';
import { SelectionProvider } from '/src/contexts/selection';
import { NotificationsFilterProvider } from '../../notifications-filter-context';
import { NotificationsTable } from '../NotificationsTable';
import { notificationsListQuery } from '../../queries';
import { useFilter } from '../../notifications-filter-context';

import { AddNotificationDropdown } from '../AddNotificationDropdown';
import { RESOURCE_META } from '../../constants';

export function EditNotificationsModal({ resourceType, resourceId }) {
  return (
    <Modal dialogClassName="!w-[640px]">
      <NotificationsFilterProvider noQueryParams={true}>
        <ModalContent resourceType={resourceType} resourceId={resourceId} />
      </NotificationsFilterProvider>
    </Modal>
  );
}

function ModalContent({ resourceType, resourceId }) {
  const { selectedSite } = useSelectedSite();
  const modal = useModal();
  const {
    order: { field, sort },
  } = useFilter();

  const queryResult = useInfiniteQuery({
    ...notificationsListQuery({
      resourceId: String(resourceId),
      siteId: selectedSite?.id,
      field,
      sort,
    }),
    enabled: Boolean(selectedSite?.id),
  });

  const { data, isFetching } = queryResult;
  const notifications = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.notificationsList?.notifications ?? [])], []) ?? [],
    [data],
  );

  useEffect(() => {
    if (data && !isFetching && notifications.length === 0) {
      modal.close();
    }
  }, [modal, data, isFetching, notifications.length]);

  return (
    <>
      <Modal.Title>Edit notifications</Modal.Title>

      <div className="text-xs">
        A notification will be sent to the following locations each time {RESOURCE_META[resourceType].actionText}.
      </div>
      <div className="-mx-7 mt-5">
        <SelectionProvider>
          <NotificationsTable
            notifications={notifications}
            queryResult={queryResult}
            inEditModal={true}
            selectable={false}
          />
        </SelectionProvider>
      </div>

      <div className="mt-6 self-start">
        <AddNotificationDropdown resourceType={resourceType} resourceId={resourceId} />
      </div>
    </>
  );
}
