import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { useAuthContext, usePermissions, useHasFeatureFlag } from '/src/hooks';
import { FEATURES, PLAN_TYPES, CAPABILITY_REASONS } from '/src/features/_global/constants';

import { ErrorsProvider } from './features/errors/components/index';

import { SnapshotsWizard } from './features/snapshots/components/creation-flow/Wizard';
import { NotFoundPage } from './features/_global/pages/NotFoundPage';
import { NotImplementedPage } from './features/_global/pages/NotImplementedPage';

import { GeneralErrorPage } from './features/_global/pages/GeneralErrorPage';
import CreateSnapshotSuccess from '/src/features/snapshots/components/creation-flow/CreateSnapshotSuccess';
import { SnapshotSelection } from '/src/features/snapshots/components/creation-flow/SnapshotSelection';
import { InstallationWizard } from './features/installation/pages/index';

import RegexSyntaxModal from '/src/features/snapshots/components/creation-flow/modals/RegexSyntaxModal';
import WildcardSyntaxModal from '/src/features/snapshots/components/creation-flow/modals/WildcardSyntaxModal';
import { SnapshotDashboard } from './features/snapshots/pages/index';
import RecordingsDashboard from './features/recordings/pages/recordings';
import LiveDashboard from './features/live-activity/pages/live';
import ErrorsDashboard from './features/errors/pages/index';
import ErrorReportPage from './features/errors/pages/[id]';
import AddonsDashboard from '/src/features/addons/pages/index';
import AddonResults from '/src/features/addons/pages/[id]';
import AddonTemplates from '/src/features/addons/pages/templates';
import NewSurveyTemplate from '/src/features/addons/pages/templates/surveys/new';
import NewCTATemplate from '/src/features/addons/pages/templates/ctas/new';
import UtilizeTemplate from '/src/features/addons/pages/templates/[id]/utilize';
import EditAddonTemplate from '/src/features/addons/pages/templates/[id]/edit';
import NewSurvey from '/src/features/addons/pages/surveys/new';
import NewCTA from '/src/features/addons/pages/ctas/new';
import EditAddon from './features/addons/pages/[id]/edit';
import CopyAddon from './features/addons/pages/[id]/copy';
import ViewAddon from './features/addons/pages/[id]/view';
import UtilizeSurveySuggestion from './features/addons/pages/surveys/[id]/newFromSuggestion';

import FunnelDashboard from './features/funnel/pages/index';
import HomeDashboard from './features/home/pages/index';

import { ChooseMethodPage } from './features/installation/pages/choose-method';
import { EmailDeveloperPage } from './features/installation/pages/developer';
import { ManualInstructions } from './features/installation/pages/manually';
import { WordPressInstructions } from './features/installation/pages/wordpress';
import { GtmInstructions } from './features/installation/pages/google-tag-manager';
import { ShopifyInstructions } from './features/installation/pages/shopify';
import { WixInstructions } from './features/installation/pages/wix';
import { SquarespaceInstructions } from './features/installation/pages/squarespace';
import { DrupalInstructions } from './features/installation/pages/drupal';
import { MagentoInstructions } from './features/installation/pages/magento';
import { JoomlaInstructions } from './features/installation/pages/joomla';
import { SegmentInstructions } from './features/installation/pages/segment';
import { InstallHelpPage } from './features/installation/pages/help';
import { CheckScriptPage } from './features/installation/pages/check-script';
import ZarazInstallPage from './features/installation/pages/zaraz';

import OptionsAdvancedTrackingScreen from './features/options/pages/advanced-tracking';
import OptionsApiScreen from './features/options/pages/api';
import OptionsCheckInstallationScreen from './features/options/pages/check-script';
import OptionsSitesScreen from './features/options/pages/sites';
import OptionsSiteSettings from './features/options/pages/site-settings';
import IntegrationsOptions from './features/options/pages/integrations';
import Notifications from './features/notifications/pages/index';

import AccountPage from './features/account/pages/index';
import AccountProfile from './features/account/pages/profile';
import AccountPlan from './features/account/pages/plan';
import AccountBilling from './features/account/pages/billing';
import Receipt from './features/account/pages/receipt';
import SsoSettingsScreen from './features/sso/pages/sso';

import AccountUsers from './features/team-and-sharing/pages/team';
import OptionsPage from './features/options/pages/index';
import ChangelogPage from './features/whats-new/pages/new';
import Player from './features/recordings/pages/player';
import TrafficAnalysisDashboard from './features/traffic-analysis/pages/traffic';

import { AbTestsDashboard } from './features/ab-testing/pages/index';
import { AbTestsResult } from './features/ab-testing/pages/show';
import AbTestCreationWizard from './features/ab-testing/pages/new';
import AbTestEditWizard from './features/ab-testing/pages/edit';
import AbTestPreview from './features/ab-testing/pages/preview';
import EditGoalPage from './features/ab-testing/pages/edit-goal';
import GoalDetailsPage from '/src/features/goals/pages/show';
import GoalsDashboard from '/src/features/goals/pages/index';
import GoalWizardPage from '/src/features/goals/pages/new';
import { NewGoalSuccessPage } from '/src/features/goals/pages/success';
import AbTestAddVariantPage from './features/ab-testing/pages/add-variant';
import AbTestEditVariantPage from './features/ab-testing/pages/edit-variant';
import { AbTestGoalSelectorPage } from './features/ab-testing/pages/goal-selector';

import { BulkSnapshotCreationPage } from '/src/features/snapshots/components/creation-flow/BulkSnapshotCreationPage';
import { SingleSnapshotCreationPage } from '/src/features/snapshots/components/creation-flow/SingleSnapshotCreationPage';
import { BulkSnapshotSettings } from '/src/features/snapshots/components/creation-flow/BulkSnapshotSettings';
import { ReviewSnapshots } from '/src/features/snapshots/components/creation-flow/ReviewSnapshots';
import { CreateWithPageCamera } from '/src/features/snapshots/components/creation-flow/CreateWithPageCamera';
import { RecapturePage } from './features/snapshots/pages/page-camera/RecapturePage';

import * as GoalEditPage from '/src/features/goals/pages/edit';
import * as GoalEditValue from '/src/features/goals/pages/edit-value';

import { SharedSessionHandler } from '/src/components/SharedSessionHandler';
import { SharedSessionWrapper } from '/src/components/SharedSessionWrapper';

import { PageEditor } from '/src/features/page-editor/pages/index';

import { Root } from '/src/components/Root';

export function Router() {
  const { currentUser, isSharing, subscription } = useAuthContext();
  const permissions = usePermissions();
  const canVisitPageEditor = useHasFeatureFlag('page-editor');

  const router = getRouter({
    currentUser,
    isSharing,
    permissions,
    subscription,
    canVisitPageEditor,
  });
  return <RouterProvider router={router} />;
}

// eslint-disable-next-line no-unused-vars
function getRouter({ currentUser, permissions, isSharing, subscription, canVisitPageEditor }) {
  const canVisitHome = permissions.can('navigate', 'HOME').allowed;
  const canVisitFunnel = permissions.can('navigate', FEATURES.FUNNEL).allowed;
  const canVisitLive = permissions.can('navigate', FEATURES.LIVE_ACTIVITY).allowed;

  const canVisitSnapshots = permissions.can('navigate', FEATURES.SNAPSHOTS).allowed;
  const canEditSnapshots = permissions.can('edit', FEATURES.SNAPSHOTS).allowed;

  const canVisitRecordings = permissions.can('navigate', FEATURES.RECORDINGS).allowed;
  const canViewRecordings = permissions.can('view', FEATURES.RECORDINGS).allowed;

  const canVisitGoals = permissions.can('navigate', FEATURES.GOALS).allowed;
  const canViewGoals = permissions.can('view', FEATURES.GOALS).allowed;
  const canEditGoals = permissions.can('edit', FEATURES.GOALS).allowed;
  const canCreateGoals = permissions.can('create', FEATURES.GOALS).allowed;

  const canVisitAbTesting = permissions.can('navigate', FEATURES.AB_TESTING).allowed;
  const canViewAbTesting = permissions.can('view', FEATURES.AB_TESTING).allowed;
  const canEditAbTesting = permissions.can('edit', FEATURES.AB_TESTING).allowed;
  const canCreateAbTesting = permissions.can('create', FEATURES.AB_TESTING).allowed;

  const canVisitTrafficAnalysis = permissions.can('navigate', FEATURES.TRAFFIC_ANALYSIS).allowed;

  const canVisitErrors = permissions.can('navigate', FEATURES.ERRORS_TRACKING).allowed;
  const canViewErrors = permissions.can('view', FEATURES.ERRORS_TRACKING).allowed;

  const canVisitAddons = permissions.can('navigate', FEATURES.ADDONS).allowed;
  const canManageAddonTemplates = permissions.can('manageTemplates', FEATURES.ADDONS).allowed;
  const canViewSurveys = permissions.can('view', FEATURES.SURVEYS).allowed;
  const canViewCTAs = permissions.can('view', FEATURES.CTAS).allowed;
  const canEditSurveys = permissions.can('edit', FEATURES.SURVEYS).allowed;
  const canEditCTAs = permissions.can('edit', FEATURES.CTAS).allowed;
  const canCreateSurveys = permissions.can('create', FEATURES.SURVEYS).allowed;
  const canCreateCTAs = permissions.can('create', FEATURES.CTAS).allowed;

  const canVisitOptions = permissions.can('navigate', FEATURES.OPTIONS).allowed;
  const canVisitAdvancedTracking = permissions.can('navigate', FEATURES.TRACKING_OPTIONS).allowed;
  const canVisitCheckInstall = permissions.can('navigate', FEATURES.INSTALLATION).allowed;
  const canVisitIntegrations = permissions.can('navigate', FEATURES.INTEGRATIONS).allowed;
  const canVisitApi = permissions.can('navigate', FEATURES.API).allowed;
  const canVisitSSO = permissions.can('navigate', FEATURES.SSO).allowed;
  const canVisitOptionSiteSettings = permissions.can('manageSiteSettings', FEATURES.SITE_SETTINGS).allowed;
  const canVisitNotifications = permissions.can('navigate', FEATURES.NOTIFICATIONS).allowed;

  const canVisitBilling = permissions.can('navigate', FEATURES.BILLING).allowed;
  const canVisitTeamAndSharing = permissions.can('navigate', FEATURES.TEAM_AND_SHARING).allowed;

  const canViewInstallation = permissions.can('view', FEATURES.INSTALLATION).allowed;
  const canViewZarazInstallation = permissions.can('viewZaraz', FEATURES.INSTALLATION).allowed;
  const canVisitInstallation = canViewInstallation || canViewZarazInstallation; //permissions.can('navigate', FEATURES.INSTALLATION).allowed;

  const canVisitWhatsNew = permissions.can('navigate', FEATURES.WHATS_NEW).allowed;

  let basePath = '/snapshots';
  if (subscription?.plan.type === PLAN_TYPES.ZARAZ) basePath = '/recordings';
  else if (permissions.can('view', FEATURES.SNAPSHOTS).reason === CAPABILITY_REASONS.UPGRADE) basePath = '/addons';

  const homeRoute = canVisitHome
    ? [
        {
          path: 'home',
          element: <HomeDashboard />,
        },
      ]
    : [];

  const funnelRoutes = canVisitFunnel
    ? [
        {
          path: 'funnel',
          element: <FunnelDashboard />,
        },
      ]
    : [];

  const liveRoutes = canVisitLive
    ? [
        {
          path: 'live',
          element: <LiveDashboard />,
        },
      ]
    : [];

  const snapshotRoutes = canVisitSnapshots
    ? {
        default: [
          {
            path: 'snapshots',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: (
                  <>
                    <SnapshotDashboard />
                  </>
                ),
              },
              {
                path: ':id',
                element: <NotImplementedPage />,
              },
            ],
          },
        ],
        wizards: canEditSnapshots
          ? [
              {
                path: 'snapshots/new',
                element: <SnapshotsWizard />,
                children: [
                  {
                    path: '',
                    element: <SnapshotSelection />,
                  },
                  {
                    path: 'bulk',
                    element: (
                      <>
                        <SnapshotSelection />
                        <BulkSnapshotCreationPage />
                      </>
                    ),
                  },
                  {
                    path: 'settings',
                    element: <BulkSnapshotSettings />,
                  },
                  {
                    path: 'single',
                    element: (
                      <>
                        <SnapshotSelection />
                        <SingleSnapshotCreationPage />
                      </>
                    ),
                  },
                  {
                    path: 'checkout',
                    element: (
                      <>
                        <SnapshotSelection />
                        <CreateWithPageCamera />
                      </>
                    ),
                  },
                  {
                    path: 'login',
                    element: (
                      <>
                        <SnapshotSelection />
                        <CreateWithPageCamera isLogin={true} />
                      </>
                    ),
                  },
                  {
                    path: 'review',
                    element: <ReviewSnapshots />,
                  },
                ],
              },
              {
                path: 'snapshots/new',
                element: <Outlet />,
                children: [
                  {
                    path: 'finish',
                    element: <CreateSnapshotSuccess />,
                  },
                  {
                    path: 'regex-syntax',
                    element: <RegexSyntaxModal />,
                  },
                  {
                    path: 'wildcard-syntax',
                    element: <WildcardSyntaxModal />,
                  },
                ],
              },
              {
                path: 'snapshots/edit/:snapshotId',
                element: <SnapshotsWizard />,
                children: [
                  {
                    path: '',
                    element: <SingleSnapshotCreationPage />,
                  },
                  {
                    path: 'review',
                    element: <ReviewSnapshots />,
                  },
                ],
              },
            ]
          : [
              {
                path: 'snapshots/*',
                element: <Navigate replace to="/snapshots" />,
              },
            ],
      }
    : {
        default: [],
        wizards: [],
      };

  const recordingsRoutes = canVisitRecordings
    ? {
        default: [
          {
            path: 'recordings',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: (
                  <>
                    <RecordingsDashboard />
                  </>
                ),
              },
              {
                path: 'settings/*',
                element: <NotImplementedPage />,
              },
            ],
          },
        ],
        player: [
          {
            path: 'recordings/:hashid?/player',
            element: canViewRecordings ? <Player /> : <Navigate to="/recordings" replace />,
          },
        ],
      }
    : {
        default: [],
        player: [],
      };

  const goalsRoutes = canVisitGoals
    ? {
        default: [
          {
            path: '/goals',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: (
                  <>
                    <GoalsDashboard />
                  </>
                ),
              },
              {
                path: ':id',
                element: canViewGoals ? <GoalDetailsPage /> : <Navigate replace to=".." />,
              },
            ],
          },
        ],
        wizards: canEditGoals
          ? [
              {
                path: '/goals',
                element: <Outlet />,
                children: [
                  {
                    path: 'new',
                    element: canCreateGoals ? <Outlet /> : <Navigate replace to=".." />,
                    children: canCreateGoals
                      ? [
                          { path: '', element: <GoalWizardPage /> },
                          { path: 'success', element: <NewGoalSuccessPage /> },
                        ]
                      : [],
                  },
                  {
                    path: ':goalId',
                    element: <Outlet />,
                    children: [
                      { path: 'edit', element: <GoalEditPage.Component /> },
                      { path: 'edit-value', element: <GoalEditValue.Component /> },
                    ],
                  },
                ],
              },
            ]
          : [],
      }
    : {
        default: [],
        wizards: [],
      };

  const abTestsRoutes = canVisitAbTesting
    ? {
        default: [
          {
            path: 'ab-tests',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <AbTestsDashboard />,
              },
              {
                path: ':id',
                element: canViewAbTesting ? <AbTestsResult /> : <Navigate replace to=".." />,
              },
            ],
          },
        ],
        wizards: canEditAbTesting
          ? [
              {
                path: 'ab-tests',
                element: <Outlet />,
                children: [
                  {
                    path: 'new',
                    element: canCreateAbTesting ? <AbTestCreationWizard /> : <Navigate replace to=".." />,
                  },
                  {
                    path: ':id',
                    element: <Outlet />,
                    children: [
                      {
                        path: 'edit',
                        element: <AbTestEditWizard />,
                      },
                      {
                        path: 'goals/new',
                        element: <AbTestGoalSelectorPage newGoal={true} />,
                      },
                      {
                        path: 'variants',
                        element: <Outlet />,
                        children: [
                          {
                            path: 'new',
                            element: <AbTestAddVariantPage />,
                          },
                          {
                            path: ':variantId/edit',
                            element: <AbTestEditVariantPage />,
                          },
                        ],
                      },
                      {
                        path: 'goals',
                        element: <AbTestGoalSelectorPage />,
                      },
                      {
                        path: 'edit/goal',
                        element: <EditGoalPage />,
                      },
                      {
                        path: 'goals/:goalId/edit',
                        element: <GoalEditPage.Component />,
                      },
                    ],
                  },
                  {
                    path: 'preview',
                    element: <AbTestPreview />,
                  },
                ],
              },
            ]
          : [
              {
                path: 'ab-test/*',
                element: <Navigate replace to="/ab-tests" />,
              },
            ],
      }
    : {
        default: [],
        wizards: [],
      };

  const trafficAnalysisRoutes = canVisitTrafficAnalysis
    ? [
        {
          path: 'traffic',
          element: (
            <>
              <TrafficAnalysisDashboard />
            </>
          ),
        },
      ]
    : [];

  const errorsRoutes = canVisitErrors
    ? [
        {
          path: 'errors',
          element: <ErrorsProvider />,
          children: [
            {
              path: '',
              element: (
                <>
                  <ErrorsDashboard />
                </>
              ),
            },
            {
              path: ':uniqueErrorId',
              element: <ErrorReportPage />,
            },
          ],
        },
      ]
    : [];

  const addonRoutes = canVisitAddons
    ? [
        {
          path: 'addons',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <AddonsDashboard />,
            },
            {
              path: 'surveys',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate replace to={{ pathname: '/addons', search: `type=${FEATURES.SURVEYS}` }} />,
                },
                {
                  path: 'new',
                  element: canCreateSurveys ? <NewSurvey /> : <Navigate replace to="/addons" />,
                },
                {
                  path: ':id/newFromSuggestion',
                  element: canCreateSurveys ? <UtilizeSurveySuggestion /> : <Navigate replace to="/addons" />,
                },
              ],
            },
            {
              path: 'ctas',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate replace to={{ pathname: '/addons', search: `type=${FEATURES.CTAS}` }} />,
                },
                {
                  path: 'new',
                  element: canCreateCTAs ? <NewCTA /> : <Navigate replace to="/addons" />,
                },
              ],
            },
            {
              path: ':id',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: canViewSurveys || canViewCTAs ? <AddonResults /> : <Navigate replace to="/addons" />,
                },
                {
                  path: 'edit',
                  element: canEditSurveys || canEditCTAs ? <EditAddon /> : <Navigate replace to="/addons" />,
                },
                {
                  path: 'copy',
                  element: canCreateSurveys || canCreateCTAs ? <CopyAddon /> : <Navigate replace to="/addons" />,
                },
                {
                  path: 'view',
                  element: canViewSurveys || canViewCTAs ? <ViewAddon /> : <Navigate replace to="/addons" />,
                },
              ],
            },
            {
              path: 'templates',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <AddonTemplates />,
                },
                {
                  path: ':id',
                  element: <Outlet />,
                  children: [
                    {
                      path: 'utilize',
                      element:
                        canCreateSurveys || canCreateCTAs ? <UtilizeTemplate /> : <Navigate replace to="/addons" />,
                    },
                    {
                      path: 'edit',
                      element: canManageAddonTemplates ? <EditAddonTemplate /> : <Navigate replace to="/addons" />,
                    },
                  ],
                },
                {
                  path: 'surveys/new',
                  element: canManageAddonTemplates ? <NewSurveyTemplate /> : <Navigate replace to="/addons" />,
                },
                {
                  path: 'ctas/new',
                  element: canManageAddonTemplates ? <NewCTATemplate /> : <Navigate replace to="/addons" />,
                },
              ],
            },
          ],
        },
        {
          path: 'surveys',
          element: <Navigate replace to={{ pathname: '/addons' }} />,
        },
      ]
    : [];

  const optionsRoutes = canVisitOptions
    ? [
        {
          path: 'options',
          element: <OptionsPage />,
          children: [
            {
              path: '',
              element: <Navigate replace to={canVisitOptionSiteSettings ? './site-settings' : './sites'} />,
            },
            {
              path: 'sites',
              element: !canVisitOptionSiteSettings ? <OptionsSitesScreen /> : <Navigate replace to=".." />,
            },
            {
              path: 'site-settings',
              element: canVisitOptionSiteSettings ? <OptionsSiteSettings /> : <Navigate replace to=".." />,
            },
            {
              path: 'advanced-tracking',
              element: canVisitAdvancedTracking ? <OptionsAdvancedTrackingScreen /> : <Navigate replace to=".." />,
            },
            {
              path: 'global-tracking',
              element: <Navigate replace to="../advanced-tracking" />,
            },
            {
              path: 'check-script',
              element: canVisitCheckInstall ? <OptionsCheckInstallationScreen /> : <Navigate replace to=".." />,
            },
            {
              path: 'integrations',
              element: canVisitIntegrations ? <IntegrationsOptions /> : <Navigate replace to=".." />,
            },
            {
              path: 'api',
              element: canVisitApi ? <OptionsApiScreen /> : <Navigate replace to=".." />,
            },
            {
              path: 'notifications',
              element: canVisitNotifications ? <Notifications /> : <Navigate replace to=".." />,
            },
            {
              path: 'sso',
              element: <Navigate replace to={{ pathname: '/account/sso' }} />,
            },
            {
              path: 'audit-trail',
              element: <Navigate replace to={{ pathname: '/account/team', search: 'tab=2' }} />,
            },
          ],
        },
      ]
    : [];

  const installationRoutes = canVisitInstallation
    ? [
        {
          path: 'install',
          element: <InstallationWizard />,
          children: [
            {
              path: '',
              element: canViewInstallation ? (
                <Navigate replace to="choose-method" />
              ) : (
                <Navigate replace to="./zaraz" />
              ),
            },
            {
              path: 'choose-method',
              element: <ChooseMethodPage />,
            },
            {
              path: 'manually',
              element: <ManualInstructions />,
            },
            {
              path: 'developer/:page?',
              element: <EmailDeveloperPage />,
            },
            {
              path: 'wordpress',
              element: <WordPressInstructions />,
            },
            {
              path: 'google-tag-manager',
              element: <GtmInstructions />,
            },
            {
              path: 'shopify',
              element: <ShopifyInstructions />,
            },
            {
              path: 'wix',
              element: <WixInstructions />,
            },
            {
              path: 'squarespace',
              element: <SquarespaceInstructions />,
            },
            {
              path: 'drupal',
              element: <DrupalInstructions />,
            },
            {
              path: 'magento',
              element: <MagentoInstructions />,
            },
            {
              path: 'joomla',
              element: <JoomlaInstructions />,
            },
            {
              path: 'segment',
              element: <SegmentInstructions />,
            },
            {
              path: 'help',
              element: <InstallHelpPage />,
            },
            {
              path: '/install/:page?/check-script',
              element: <CheckScriptPage />,
            },
            {
              path: 'zaraz',
              element: canViewZarazInstallation ? <ZarazInstallPage /> : <Navigate replace to="./choose-method" />,
            },
            {
              path: '*',
              element: canViewInstallation ? (
                <Navigate replace to="./choose-method" />
              ) : (
                <Navigate replace to="./zaraz" />
              ),
            },
          ],
        },
      ]
    : [];

  const accountRoutes = {
    default: [
      {
        path: 'account',
        element: <AccountPage />,
        children: [
          {
            path: '',
            element: <Navigate replace to="./profile" />,
          },
          {
            path: 'profile',
            element: <AccountProfile />,
          },
          {
            path: 'plan',
            element: canVisitBilling ? <AccountPlan /> : <Navigate replace to=".." />,
          },
          {
            path: 'billing',
            element: canVisitBilling ? <AccountBilling /> : <Navigate replace to=".." />,
          },
          {
            path: 'notifications',
            element: <Navigate replace to="../profile" />,
          },
          {
            path: 'crazy-egg-emails',
            element: <Navigate replace to="../profile" />,
          },
          {
            path: 'sso',
            element: canVisitSSO ? <SsoSettingsScreen /> : <Navigate replace to=".." />,
          },
        ],
      },
      {
        path: 'account',
        element: <Outlet />,
        children: [
          {
            path: 'team',
            element: canVisitTeamAndSharing ? <AccountUsers /> : <Navigate replace to=".." />,
          },
        ],
      },
    ],
    receipt: [
      {
        path: 'account/receipt/:id',
        element: canVisitBilling ? <Receipt /> : <NotFoundPage />,
      },
    ],
  };

  if (isSharing) {
    return createBrowserRouter([
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: '/s/:sharingCode',
        element: <SharedSessionHandler />,
        errorElement: <GeneralErrorPage />,
      },
      {
        path: '/not-found',
        element: <NotFoundPage />,
      },
      {
        path: '/',
        element: <SharedSessionWrapper />,
        errorElement: <GeneralErrorPage />,
        children: [
          {
            path: '',
            element: <Root navbar={false} />,
            children: [
              {
                path: 'errors',
                element: canViewErrors ? <ErrorsProvider /> : <NotFoundPage />,
                children: canViewErrors
                  ? [
                      {
                        path: ':uniqueErrorId',
                        element: <ErrorReportPage />,
                      },
                    ]
                  : [],
              },
              {
                path: 'recordings/:hashid/player',
                element: canViewRecordings ? <Player /> : <NotFoundPage />,
              },
              {
                path: 'addons/:id',
                element: canViewSurveys || canViewCTAs ? <AddonResults /> : <NotFoundPage />,
              },
              {
                path: 'ab-tests/:id',
                element: canViewAbTesting ? <AbTestsResult /> : <NotFoundPage />,
              },
              {
                path: 'goals/:id',
                element: canViewGoals ? <GoalDetailsPage /> : <NotFoundPage />,
              },
            ],
          },
        ],
      },
    ]);
  }

  return createBrowserRouter([
    {
      path: '*',
      element: <NotFoundPage />,
    },
    {
      path: '/s/:sharingCode',
      element: <SharedSessionHandler />,
      errorElement: <GeneralErrorPage />,
    },
    {
      path: '/',
      element: <Root />,
      errorElement: <GeneralErrorPage />,
      children: [
        {
          path: '',
          element: <Navigate replace to={basePath} />,
        },
        ...homeRoute,
        ...funnelRoutes,
        ...liveRoutes,
        ...snapshotRoutes.default,
        ...recordingsRoutes.default,
        ...goalsRoutes.default,
        ...abTestsRoutes.default,
        ...trafficAnalysisRoutes,
        ...errorsRoutes,
        ...addonRoutes,
        ...optionsRoutes,
        {
          path: 'page-edits',
          element: <NotImplementedPage />,
        },
        {
          path: '/new',
          element: canVisitWhatsNew ? <ChangelogPage /> : <NotFoundPage />,
        },
        ...accountRoutes.default,
        {
          path: 'powerup',
          element: <Navigate replace to={basePath} />,
        },
        {
          path: 'login',
          element: <NotImplementedPage />,
        },
        {
          path: 'subscriptions',
          element: <Outlet />,
          children: [
            {
              path: 'new/bundled/plans/monthly',
              element: <NotImplementedPage />,
            },
            {
              path: ':id',
              element: <Outlet />,
              children: [
                {
                  path: 'edit/plans/monthly',
                  element: <NotImplementedPage />,
                },
                {
                  path: 'edit/plans/yearly',
                  element: <NotImplementedPage />,
                },
                {
                  path: 'cancel',
                  element: <NotImplementedPage />,
                },
              ],
            },
          ],
        },
      ],
    },
    // Wizards, Players and everything else that doesn't have navbars
    {
      path: '/',
      element: <Root navBar={false} />,
      errorElement: <GeneralErrorPage />,
      children: [
        ...snapshotRoutes.wizards,
        ...recordingsRoutes.player,
        ...goalsRoutes.wizards,
        ...abTestsRoutes.wizards,
        ...installationRoutes,
        ...accountRoutes.receipt,
        { path: 'page-editor', element: canVisitPageEditor ? <PageEditor /> : <NotFoundPage /> },
      ],
    },
    {
      path: '/page_camera/recapture',
      element: <RecapturePage />,
      errorElement: <GeneralErrorPage />,
    },
    {
      path: '/error',
      element: <GeneralErrorPage />,
      errorElement: <GeneralErrorPage />,
    },
  ]);
}
