import { FEATURES, PERMISSION_NOT_ALLOWED } from '/src/features/_global/constants';

export const NOTIFICATIONS_PERMISSIONS = {
  [FEATURES.NOTIFICATIONS]: ({ capabilities, currentUser }) => ({
    navigate: currentUser?.features.includes('channels')
      ? capabilities?.notifications.permissions.nav
      : PERMISSION_NOT_ALLOWED,
    edit: currentUser?.features.includes('channels')
      ? capabilities?.notifications.permissions.edit
      : PERMISSION_NOT_ALLOWED,
  }),
};
