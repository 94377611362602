import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { DashboardPage } from '/src/components/Page';
import { TabHeader, Tab } from '/src/components/headers/tab-header';
import { SEO } from '/src/components/SEO';
import { usePermissions } from '/src/hooks';
import { FEATURES } from '/src/features/_global/constants';

// eslint-disable-next-line no-restricted-syntax
export default function OptionsPage() {
  const permissions = usePermissions();

  const canVisitSites = permissions.can('navigate', FEATURES.SITE_SETTINGS).allowed;
  const canVisitAdvancedTracking = permissions.can('navigate', FEATURES.TRACKING_OPTIONS).allowed;
  const canVisitApi = permissions.can('navigate', FEATURES.API).allowed;
  const canViewCheckInstall = permissions.can('navigate', FEATURES.INSTALLATION).allowed;
  const canVisitIntegrations = permissions.can('navigate', FEATURES.INTEGRATIONS).allowed;
  const canVisitOptionSiteSettings = permissions.can('manageSiteSettings', FEATURES.SITE_SETTINGS).allowed;
  const canVisitNotifications = permissions.can('navigate', FEATURES.NOTIFICATIONS).allowed;

  return (
    <DashboardPage>
      <SEO title="Options" />
      <TabHeader title="Options">
        {canVisitSites && !canVisitOptionSiteSettings ? <Tab to="/options/sites">Sites (Domains)</Tab> : null}
        {canVisitSites && canVisitOptionSiteSettings ? <Tab to="/options/site-settings">Site Settings</Tab> : null}
        {canVisitAdvancedTracking ? <Tab to="/options/advanced-tracking">Advanced Tracking</Tab> : null}
        {canViewCheckInstall ? <Tab to="/options/check-script">Check Installation</Tab> : null}
        {canVisitIntegrations ? <Tab to="/options/integrations">Integrations</Tab> : null}
        {canVisitApi ? <Tab to="/options/api">API</Tab> : null}
        {canVisitNotifications ? <Tab to="/options/notifications">Notifications</Tab> : null}
      </TabHeader>
      <div className="mx-10">
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </div>
    </DashboardPage>
  );
}
