import { useContext, useReducer, createContext, useState, useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { produce } from 'immer';

import { getParam, getInitialValue } from '/src/utils/location';
import { useQueryParams } from '/src//hooks';
import { NOTIFICATION_TYPES, NOTIFICATIONS_ORDER } from './constants';
import { SORT_ORDER_TYPES } from '/src/features/_global/constants';

const FILTER_ACTIONS = {
  SET: 'SET',
  RESET: 'RESET',
  SET_ORDER_FIELD: 'SET_ORDER_FIELD',
  SET_ORDER_SORT: 'SET_ORDER_SORT',
};
const { SET, RESET, SET_ORDER_FIELD, SET_ORDER_SORT } = FILTER_ACTIONS;

const FilterContext = createContext();

const initialState = (noQueryParams) => {
  if (noQueryParams) {
    return {
      type: null,
      search: '',
      order: {
        field: NOTIFICATIONS_ORDER.CREATED_AT,
        sort: SORT_ORDER_TYPES.DESC,
      },
    };
  }
  return {
    type: getInitialValue('type', Object.values(NOTIFICATION_TYPES)),
    search: getParam('search') || '',
    order: {
      field: getInitialValue('order', [...Object.values(NOTIFICATIONS_ORDER)]) || NOTIFICATIONS_ORDER.CREATED_AT,
      sort: getInitialValue('sort', [SORT_ORDER_TYPES.ASC, SORT_ORDER_TYPES.DESC]) || SORT_ORDER_TYPES.DESC,
    },
  };
};

export function NotificationsFilterProvider({ children, noQueryParams = false }) {
  function filterReducer(state = initialState(noQueryParams), action) {
    switch (action.type) {
      case SET:
        return {
          ...state,
          ...action.payload,
        };
      case SET_ORDER_FIELD:
        return produce(state, (draft) => {
          draft.order.field = action.payload;
        });
      case SET_ORDER_SORT:
        return produce(state, (draft) => {
          draft.order.sort = action.payload;
        });
      case RESET:
        return {
          ...initialState(noQueryParams),
        };
      default:
        return state;
    }
  }

  const [filter, filterDispatch] = useReducer(filterReducer, initialState(noQueryParams));
  const [filterState, setFilterState] = useState({ filter, filterDispatch });
  if (!isEqual(filter, filterState.filter)) {
    setFilterState({ filter, filterDispatch });
  }
  return <FilterContext.Provider value={filterState}>{children}</FilterContext.Provider>;
}

export function useFilter({ noQueryParams = false } = {}) {
  const { set: queryParamsSet, removeAll } = useQueryParams();
  const { filter, filterDispatch } = useContext(FilterContext);

  const setTypeFilter = useCallback(
    (type) => {
      queryParamsSet('type', type);
      filterDispatch({
        type: SET,
        payload: {
          type,
        },
      });
    },
    [filterDispatch, queryParamsSet],
  );

  const setSearch = useCallback(
    (search) => {
      queryParamsSet('search', search);
      filterDispatch({
        type: SET,
        payload: {
          search,
        },
      });
    },
    [queryParamsSet, filterDispatch],
  );

  const setSort = useCallback(
    (sort) => {
      if (!noQueryParams) queryParamsSet('sort', sort);
      filterDispatch({ type: SET_ORDER_SORT, payload: sort });
    },
    [filterDispatch, queryParamsSet, noQueryParams],
  );

  const setOrder = useCallback(
    (order) => {
      if (!noQueryParams) queryParamsSet('order', order);
      filterDispatch({ type: SET_ORDER_FIELD, payload: order });
    },
    [filterDispatch, queryParamsSet, noQueryParams],
  );

  function reset() {
    removeAll(['search', 'type']);
    filterDispatch({
      type: RESET,
    });
    window.dispatchEvent(new Event('filters:reset'));
  }

  return {
    ...filter,
    setTypeFilter,
    setSearch,
    setSort,
    setOrder,
    reset,
  };
}
