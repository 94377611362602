import { Button, Spinner } from '@crazyegginc/hatch';

import { useModal, useMutation, useNotifications } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { deleteNotificationMutation } from '../../mutations';

export function DeleteWarningModal({ id, resourceType, resourceId, BackToModal }) {
  const modal = useModal();
  const notifications = useNotifications();

  const mutation = useMutation(deleteNotificationMutation);

  return (
    <Modal dialogClassName="!p-6 !w-[365px]">
      <Modal.Title>Delete notification?</Modal.Title>

      <div className="text-body-5">Are you sure you want to delete this notification?</div>

      <Modal.Actions>
        <Button
          disabled={mutation.isLoading}
          variant="warning"
          onClick={() => {
            mutation.mutate(
              {
                ids: [id],
              },
              {
                onError: () => {
                  notifications.error({
                    title: `Failed to delete notification`,
                    content: 'Please try again.',
                    timeout: 4000,
                  });
                },
                onSuccess: () => {
                  notifications.success({
                    content: `Notification deleted successfully.`,
                    timeout: 3000,
                  });
                  BackToModal
                    ? modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />)
                    : modal.close();
                },
              },
            );
          }}
        >
          {mutation.isLoading && (
            <span className="mr-2.5">
              <Spinner />
            </span>
          )}
          {mutation.isLoading ? 'Deleting...' : 'Yes, delete notification'}
        </Button>

        <Button
          variant="cancel"
          onClick={() =>
            BackToModal
              ? modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />)
              : modal.close()
          }
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
