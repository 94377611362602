import { Button } from '@crazyegginc/hatch';

import { useModal } from '/src/hooks';
import { Modal } from '/src/contexts/modal';
import { RESOURCE_META } from '../../constants';
import { SlackModal } from './SlackModal';
import { EmailModal } from './EmailModal';
import { APIModal } from './APIModal';
import { ZapierModal } from './ZapierModal';

import { ReactComponent as SlackIcon } from '@crazyegginc/hatch/dist/images/logo-slack.svg';
import { ReactComponent as ZapierIcon } from '@crazyegginc/hatch/dist/images/logo-zapier-integrations.svg';
import { ReactComponent as MailIcon } from '@crazyegginc/hatch/dist/images/icon-mail.svg';
import { ReactComponent as CodeIcon } from '@crazyegginc/hatch/dist/images/icon-code-outline.svg';

export function GetNotifiedModal({ resourceType, resourceId }) {
  const modal = useModal();

  return (
    <Modal>
      <Modal.Title>Get Notified</Modal.Title>

      <p className="text-body-2">We’ll send you a notification when {RESOURCE_META[resourceType].actionText}.</p>
      <p className="text-header-5 mt-2">Where would you like to be notified?</p>

      <Modal.Actions className="!mt-5">
        <Button
          variant="secondary"
          onClick={() =>
            modal.show(
              <SlackModal
                resourceType={resourceType}
                resourceId={resourceId}
                BackToModal={GetNotifiedModal}
                closeAfterAction={true}
              />,
            )
          }
        >
          <SlackIcon className="mr-2 h-4 w-4 shrink-0" />
          Slack
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            modal.show(
              <EmailModal
                resourceType={resourceType}
                resourceId={resourceId}
                BackToModal={GetNotifiedModal}
                closeAfterAction={true}
              />,
            )
          }
        >
          <MailIcon className="mr-2 h-4 w-4 shrink-0 fill-current" />
          Email
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            modal.show(
              <APIModal
                resourceType={resourceType}
                resourceId={resourceId}
                BackToModal={GetNotifiedModal}
                closeAfterAction={true}
              />,
            )
          }
        >
          <CodeIcon className="mr-2 h-4 w-4 shrink-0 fill-current" />
          API
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            modal.show(
              <ZapierModal resourceType={resourceType} resourceId={resourceId} BackToModal={GetNotifiedModal} />,
            )
          }
        >
          <ZapierIcon className="mr-2 h-4 w-4 shrink-0" />
          Zapier
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
