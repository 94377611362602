import { useNavigate } from 'react-router-dom';
import { Tooltip, capitalize, Button } from '@crazyegginc/hatch';
import classNames from 'classnames';

import { usePermissions, useMetric, useAuthContext } from '/src/hooks';
import { inflect } from '/src/utils/string';
import { featureUsage } from '/src/utils/features';
import { getDaysRemainingInMonthServerTime } from '/src/utils/date';
import { NavUpgradeButton } from '/src/components/layout/navbar/NavUpgradeButton';

import { FEATURES } from '/src/features/_global/constants';
import { MANAGE_SUBSCRIPTION_PATH, SUBSCRIPTION_STATES } from '/src/features/_global/constants';

import { ReactComponent as LockIcon } from '@crazyegginc/hatch/dist/images/icon-lock-filled.svg';

export function FeatureUsage({ locked = false, expanded = true, cta_id = 'navbar' }) {
  const { currentAccount, capabilities, subscription } = useAuthContext();
  const permissions = usePermissions();
  const trackMetric = useMetric();

  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;

  const navigate = useNavigate();

  function renderCtaText() {
    if (!locked) return 'Upgrade Now';
    if (subscription.state === SUBSCRIPTION_STATES.EXPIRED) return 'Reactivate';
    return 'Choose a Plan';
  }

  const usage = featureUsage(capabilities.account.quotas.monthlyPageViews, 0);
  const daysRemaining = getDaysRemainingInMonthServerTime();

  let visits = parseInt(capabilities.account.quotas.monthlyPageViews.current);
  const limit = parseInt(capabilities.account.quotas.monthlyPageViews.limit);

  if (visits > limit) visits = limit;

  return (
    <div className="flex flex-col items-center" data-testid="usage-component">
      <Tooltip
        placement="right"
        arrowSkiddingPercent="10"
        skiddingPercent="38"
        interactive
        show={!locked}
        tooltipContent={
          <div className="mb-2.5 ml-2 mr-5 mt-4 w-[250px] text-left">
            <div className="mb-5 text-md font-semibold">Your pageviews usage</div>
            <div className="text-xs font-normal">
              <>
                {usage < 100 ? (
                  <span>
                    You’ve reached {usage}% of your visits this month. When you reach 100%, tracking will stop.
                  </span>
                ) : (
                  <>
                    {canManageSubscription ? (
                      <span>
                        You’ve used up all of your pageviews this month. Upgrade now or wait {daysRemaining}{' '}
                        {inflect('day', daysRemaining)} (until 1st of the month) to continue tracking data.
                      </span>
                    ) : (
                      <span>
                        You’ve used up all of your pageviews this month. Contact the account owner{' '}
                        <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link">
                          {currentAccount.ownerEmail}
                        </a>{' '}
                        to upgrade, or wait {daysRemaining} {inflect('day', daysRemaining)} to continue tracking data.
                      </span>
                    )}
                  </>
                )}
              </>
              {canManageSubscription ? (
                <Button
                  size="xs"
                  theme="dark"
                  className="!mt-2.5"
                  onMouseDown={() => {
                    if (!locked) {
                      trackMetric('upgrade_cta_click', `${cta_id}_pageviews_usage_warning`);
                    }
                  }}
                  onClick={() => {
                    navigate(MANAGE_SUBSCRIPTION_PATH);
                  }}
                >
                  Upgrade Now
                </Button>
              ) : null}
            </div>
          </div>
        }
      >
        <div className="relative">
          {expanded ? (
            <div
              className={classNames('flex w-[150px] flex-col', {
                'opacity-30': locked,
              })}
            >
              <span className="text-body-4 mb-1.25 text-white">{usage}% pageviews used</span>

              <LinearUsageMeter percentage={usage} />

              <span className="text-caption mt-1.25 text-white">
                {visits.toLocaleString()} / {limit.toLocaleString()}
              </span>
            </div>
          ) : (
            <div
              className={classNames('relative h-9 w-9', {
                'opacity-30': locked,
              })}
            >
              <RadialUsageMeter percentage={usage} />
              <span className="text-caption absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 select-none text-white">
                {usage}%
              </span>
            </div>
          )}
          {locked && (
            <LockIcon
              className={classNames(
                'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-current text-cadet-blue-500',
                {
                  'h-12 w-12': expanded,
                  'h-7 w-7': !expanded,
                },
              )}
              aria-label="account locked"
            />
          )}
        </div>
      </Tooltip>
      <div className="mt-5">
        {currentAccount.isOwner && (
          <NavUpgradeButton cta_id="navbar" expanded={expanded} isUpgrade={!locked} textContent={renderCtaText()} />
        )}
      </div>
    </div>
  );
}

function LinearUsageMeter({ percentage }) {
  return (
    <div className="min-w-11 relative h-2 w-full overflow-hidden rounded-full bg-[#001f35]">
      <div
        className={classNames(`absolute left-0 top-0 h-2 rounded-full`, {
          'bg-[#75ade2]': percentage < 90,
          'bg-radical-red-500': percentage >= 90,
        })}
        style={{ width: `${percentage}%` }}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={percentage}
      />
    </div>
  );
}

function RadialUsageMeter({ percentage = 0 }) {
  const strokeFill = String(339.292 * parseFloat(percentage / 100));
  return (
    <svg width="35" height="35" viewBox="0 0 120 120" className="-rotate-90">
      <circle cx="60" cy="60" r="54" fill="none" stroke={percentage >= 90 ? '#fa3c66' : '#407BB5'} strokeWidth="12" />
      <circle
        cx="60"
        cy="60"
        r="54"
        fill="none"
        stroke="#00335E"
        strokeWidth="12"
        strokeDasharray={`339.292 ${strokeFill}`}
        strokeDashoffset="339.292"
      />
    </svg>
  );
}

export function DashboardFeatureUsage({ feature }) {
  const { currentAccount, capabilities, sessionInfo } = useAuthContext();

  if (currentAccount.isReadOnly || sessionInfo.isImpersonatedByReadOnly) {
    // don't show the usage for read-only users
    // TODO: possibly add another state for read-only users
    return null;
  }

  let current, limit, quota;
  switch (feature) {
    case FEATURES.RECORDINGS:
      quota = capabilities.recordings.quotas.monthlyRecordings;
      break;
    case FEATURES.SNAPSHOTS:
      quota = capabilities.snapshots.quotas.totalSnapshots;
      break;
  }
  ({ current, limit } = quota);

  let usage = 0;
  if (limit) {
    usage = Math.min(Math.floor((current / limit) * 100), 100);
  }
  const text = `${capitalize(feature)} used`;

  return (
    <DashboardUsageTooltip used={current} total={limit} feature={feature}>
      <div className="mr-[30px]">
        <div className="text-caption mb-1.5">
          <span className="font-semibold">{`${usage >= 100 ? limit.toLocaleString() : current.toLocaleString()} / ${limit.toLocaleString()}`}</span>{' '}
          {text}
        </div>
        <div className="h-1.25 w-full min-w-[130px] rounded-lg bg-mystic-500">
          <div
            className={classNames('opacity-1 h-1.25 rounded-lg', {
              'bg-dodger-blue-500': usage < 90,
              'bg-radical-red-500': usage >= 90,
            })}
            style={{ width: `${usage}%` }}
            role="progressbar"
            aria-valuenow={usage}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    </DashboardUsageTooltip>
  );
}

function DashboardUsageTooltip({ children, used, total, feature }) {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const trackMetric = useMetric();
  const usage = Math.min(Math.floor((used / total) * 100), 100);
  const { currentAccount } = useAuthContext();
  const canManageSubscription = permissions.can('edit', FEATURES.BILLING).allowed;

  function ContactAccountOwner({ exceeded = true }) {
    return (
      <span>
        Please contact the account owner{' '}
        <a href={`mailto:${currentAccount.ownerEmail}`} className="text-link">
          {currentAccount.ownerEmail}
        </a>{' '}
        {exceeded ? <span>to upgrade the plan.</span> : <span>if you would like to access more.</span>}
      </span>
    );
  }

  function renderSnapshotsContent() {
    if (usage === 100) {
      return (
        <>
          <span>You’ve used all of the Snapshots available on your plan.</span>{' '}
          {canManageSubscription ? (
            <span>
              Upgrade for access to more or export and delete old Snapshots (
              <a className="underline" href="http://help.crazyegg.com/article/353-exporting">
                show me how
              </a>
              ).
            </span>
          ) : (
            <ContactAccountOwner />
          )}
        </>
      );
    }
    if (usage >= 90) {
      return (
        <>
          <span>
            You’ve used {used.toLocaleString()} of the {total.toLocaleString()} Snapshots available on your plan.
          </span>{' '}
          {canManageSubscription ? (
            <span>Upgrade for access to more (or delete a few if you need to create more).</span>
          ) : (
            <ContactAccountOwner exceeded={false} />
          )}
        </>
      );
    }
    return (
      <>
        <span>You have {(total - used).toLocaleString()} Snapshots remaining on your plan.</span>{' '}
        {canManageSubscription ? (
          <span>Upgrade your plan if you’d like access to more.</span>
        ) : (
          <ContactAccountOwner exceeded={false} />
        )}
      </>
    );
  }

  function renderRecordingsContent() {
    if (usage === 100) {
      return (
        <>
          <span>You’ve used all of the Recordings available on your plan.</span>{' '}
          {canManageSubscription ? <span>Upgrade for access to more.</span> : <ContactAccountOwner />}
        </>
      );
    }
    return <>You have reached {usage}% of your recordings this month. When you reach 100%, recording will stop.</>;
  }

  return (
    <Tooltip
      placement="bottom"
      arrowSkiddingPercent="10"
      skiddingPercent="20"
      interactive
      tooltipContent={
        <div className="mb-2.5 ml-2 mr-5 mt-4 w-[250px] text-left">
          <div className="mb-5 text-md font-semibold">Your {capitalize(feature)} usage</div>
          <div className="text-xs font-normal">
            {feature === FEATURES.SNAPSHOTS ? renderSnapshotsContent() : renderRecordingsContent()}
            {canManageSubscription ? (
              <Button
                size="xs"
                theme="dark"
                className="!mt-2.5"
                onMouseDown={() => trackMetric('upgrade_cta_click', `${feature.toLowerCase()}_dashboard_usage_warning`)}
                onClick={() => {
                  navigate(MANAGE_SUBSCRIPTION_PATH);
                }}
              >
                Upgrade Now
              </Button>
            ) : null}
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
