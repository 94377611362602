import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Spinner, Autocomplete } from '@crazyegginc/hatch';

import { useModal, useMutation, useNotifications, useSelectedSite } from '/src/hooks';
import { createNotificationMutation, editNotificationMutation } from '../../mutations';
import { channelsListQuery } from '../../queries';
import { DeleteWarningModal } from './DeleteWarningModal';
import { Modal } from '/src/contexts/modal';

import { RESOURCE_META, NOTIFICATION_TYPES } from '../../constants';
import { normalizeUrl } from '/src/utils/url';
import { superLenientUrlRegex } from '/src/utils/regex';

const validationSchema = yup.object().shape({
  url: yup.string().required('Please provide a URL.').matches(superLenientUrlRegex, 'Please provide a valid URL.'),
});

export function APIModal({ notification, resourceType, resourceId, BackToModal, closeAfterAction = false }) {
  const modal = useModal();
  const notifications = useNotifications();
  const { selectedSite } = useSelectedSite();
  const siteId = notification?.siteId ?? selectedSite.id;
  const editing = !!notification;

  const createMutation = useMutation(createNotificationMutation);
  const editMutation = useMutation(editNotificationMutation);
  const mutation = editing ? editMutation : createMutation;

  const { action, actionText } = RESOURCE_META[resourceType];

  const { data, isFetching } = useQuery(channelsListQuery({ siteId, type: NOTIFICATION_TYPES.API }));

  const existingUrls = useMemo(
    () => (data?.channelsList.map((c) => c.destination) ?? []).map((x) => ({ value: x })),
    [data],
  );

  return (
    <Modal>
      <Modal.Title>{editing ? 'Edit API Notification' : 'Get Notified via API'}</Modal.Title>

      <div className="text-body-2 mb-3.75">
        {editing
          ? `Data is being sent to API endpoint ${notification.channel.destination} when ${actionText}.`
          : `We’ll send data to your API endpoint when ${actionText}. What URL do you want this to be posted to?`}
      </div>

      <Formik
        initialValues={{
          url: editing ? notification.channel.destination : '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const existingChannel = data?.channelsList.find((c) => c.destination === values.url);
          const vars = editing
            ? {
                notificationId: notification.id,
                siteId: notification.siteId,
                channelId: existingChannel ? existingChannel.id : null,
                channelDestination: existingChannel ? null : values.url,
              }
            : {
                action,
                channelId: existingChannel ? existingChannel.id : null,
                channelDestination: existingChannel ? null : values.url,
                channelType: NOTIFICATION_TYPES.API,
                resource: resourceType,
                resourceId: String(resourceId),
              };
          mutation.mutate(vars, {
            onError: (error) => {
              const isDuplicateError = error.graphQLErrors?.[0]?.originalError?.message?.match(
                /notification: has already been taken/,
              );

              notifications.error({
                title: `Failed to ${editing ? 'edit' : 'create'} notification`,
                content: isDuplicateError ? 'This notification already exists.' : 'Please try again.',
                timeout: 4000,
                context: { error },
                skipHoneybadger: isDuplicateError ? true : false,
              });
            },
            onSuccess: () => {
              notifications.success({
                content: `Notification ${editing ? 'edited' : 'created'} successfully.`,
                timeout: 3000,
              });
              if (closeAfterAction) {
                modal.close();
              } else {
                modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />);
              }
            },
          });
        }}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
          return (
            <Form className="w-full">
              <Autocomplete
                label="URL:"
                labelClassName="font-semibold"
                options={existingUrls}
                value={values.url}
                onChange={(value) => setFieldValue('url', value)}
                onBlur={() => setFieldTouched('url', true)}
                inputFormatter={(value) => normalizeUrl(value, { appendSlash: false })}
                allowCustom={true}
                renderInPortal={true}
                popperClassName="!z-[100000]"
                error={touched.url && errors.url ? errors.url : null}
                loading={isFetching}
              />

              <Modal.Actions className="justify-between">
                <div className="flex items-center space-x-2.5">
                  <Button disabled={mutation.isLoading} type="submit">
                    {mutation.isLoading && (
                      <span className="mr-2.5">
                        <Spinner />
                      </span>
                    )}
                    {editing && (mutation.isLoading ? 'Saving...' : 'Save')}
                    {!editing && (mutation.isLoading ? 'Creating...' : 'Create notification')}
                  </Button>

                  <Button
                    variant="cancel"
                    onClick={() =>
                      BackToModal
                        ? modal.show(<BackToModal resourceType={resourceType} resourceId={resourceId} />)
                        : modal.close()
                    }
                    className="disabled:bg-transparent"
                  >
                    {editing ? 'Cancel' : 'Back'}
                  </Button>
                </div>
                {editing ? (
                  <Button
                    variant="warning"
                    onClick={() => {
                      modal.show(
                        <DeleteWarningModal
                          id={notification.id}
                          resourceType={notification.resource}
                          resourceId={notification.resourceId}
                          BackToModal={BackToModal}
                        />,
                      );
                    }}
                  >
                    Delete notification
                  </Button>
                ) : null}
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
