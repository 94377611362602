import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { SelectionProvider } from '/src/contexts/selection';
import { NoNotificationsWall } from './paywalls/NoNotificationsWall';
import { NotificationsFilters } from './NotificationsFilters';
import { NotificationsTable } from './NotificationsTable';
import { BulkAction } from '/src/components/bulk-action/BulkAction';
import { NoMatchesWall } from '../../_global/paywalls/NoMatchesWall';

import { notificationsListQuery } from '../queries';
import { useSelectedSite } from '/src/hooks';
import { useFilter } from '../notifications-filter-context';

import { BULK_ACTIONABLE_ITEMS } from '/src/features/_global/constants';

export function NotificationsContent() {
  const {
    search,
    reset,
    type,
    order: { field, sort },
  } = useFilter();
  const { selectedSite } = useSelectedSite();

  const queryResult = useInfiniteQuery({
    ...notificationsListQuery({
      search,
      type,
      field,
      sort,
      siteId: selectedSite?.id ?? undefined,
    }),
    enabled: Boolean(selectedSite),
  });

  const { data, isFetching } = queryResult;
  const notifications = useMemo(
    () => data?.pages.reduce((acc, page) => [...acc, ...(page.notificationsList?.notifications ?? [])], []) ?? [],
    [data],
  );

  if (data && !isFetching && notifications.length === 0 && !search && !type) {
    return <NoNotificationsWall />;
  }

  return (
    <>
      <NotificationsFilters />
      <SelectionProvider>
        {!isFetching && selectedSite && !notifications.length && (!!search || type) ? (
          <NoMatchesWall reset={reset} text="matches, try clearing the filters" actionText="filters" />
        ) : (
          <NotificationsTable notifications={notifications} queryResult={queryResult} />
        )}
        <BulkAction feature={BULK_ACTIONABLE_ITEMS.NOTIFICATIONS} />
      </SelectionProvider>
    </>
  );
}
