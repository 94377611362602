import { gql } from '@urql/core';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const notificationsFeatureKeys = createQueryKeys('notificationsFeature', {
  notificationsList: ({ siteId, limit, type, field, sort, search, resourceId }) => [
    {
      siteId,
      limit,
      type,
      order: {
        field,
        sort,
      },
      search,
      resourceId,
    },
  ],
  channelsList: ({ siteId, type }) => [
    {
      siteId,
      type,
    },
  ],
});

export const notificationsListQuery = ({ siteId, limit, type, field, sort, search, resourceId }) => ({
  ...notificationsFeatureKeys.notificationsList({ siteId, limit, type, field, sort, search, resourceId }),
  getNextPageParam: (lastPage) =>
    lastPage.notificationsList.hasNextPage ? lastPage.notificationsList.nextPageCursor : undefined,
  meta: {
    query: gql`
      query NotificationsList(
        $siteId: Int
        $cursor: String
        $limit: Int
        $type: ChannelType
        $order: NotificationsListOrder
        $search: String
        $resourceId: String
      ) {
        notificationsList(
          siteId: $siteId
          cursor: $cursor
          limit: $limit
          type: $type
          order: $order
          search: $search
          resourceId: $resourceId
        ) {
          hasNextPage
          nextPageCursor
          notifications {
            action
            channel {
              id
              destination
              type
            }
            createdAt
            createdBy {
              name
            }
            enabled
            id
            resource
            resourceId
            resourceName
            siteId
          }
        }
      }
    `,
  },
});

export const channelsListQuery = ({ siteId, type }) => ({
  ...notificationsFeatureKeys.channelsList({ siteId, type }),
  meta: {
    query: gql`
      query ChannelsList($siteId: Int!, $type: ChannelType) {
        channelsList(siteId: $siteId, type: $type) {
          id
          destination
          type
        }
      }
    `,
  },
});
