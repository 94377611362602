import { useState, useMemo } from 'react';
import { Button } from '@crazyegginc/hatch';

import { Modal } from '/src/contexts/modal';
import { useMutation, useModal } from '/src/hooks';

import { balanceWeights } from '/src/features/ab-testing/utils';
import { VariantWeightsPanel } from '/src/features/ab-testing/components/VariantWeightsPanel';

import { abTestSetTrafficSplitMutation } from '/src/features/ab-testing/mutations';

export function AdjustWeightsModal({ abTest, onSubmit, readOnly = false, onCancel = null, onSuccess = null }) {
  const modal = useModal();
  const [autoReweight, setAutoReweight] = useState(() => abTest.autoReweight);
  const [variants, setVariants] = useState(() => balanceWeights(abTest.variants).variants);
  const [isDirty, setIsDirty] = useState(() => false);

  const variantsPayload = useMemo(() => {
    if (!autoReweight) {
      return balanceWeights(variants).variants.map((variant) => ({ id: variant.id, weight: variant.weight }));
    } else {
      return [];
    }
  }, [variants, autoReweight]);

  const totalWeight = useMemo(() => {
    return balanceWeights(variants).total;
  }, [variants]);

  const isValid = useMemo(() => {
    if (autoReweight === true) {
      return true;
    } else {
      return totalWeight === 100;
    }
  }, [autoReweight, totalWeight]);

  const { mutate, isLoading } = useMutation(abTestSetTrafficSplitMutation);

  return (
    <Modal dialogClassName="!w-[640px] !p-[30px]">
      <Modal.Title>Traffic split</Modal.Title>

      <VariantWeightsPanel
        abTest={{ ...abTest, autoReweight }}
        onSelect={(value) => {
          if (!isDirty) setIsDirty(true);
          setAutoReweight(value);
        }}
        onChange={(value) => {
          if (!isDirty) setIsDirty(true);
          setVariants(value);
        }}
        readOnly={readOnly}
      />

      {!readOnly ? (
        <Modal.Actions>
          <Button
            onClick={() => {
              mutate(
                { id: abTest.id, autoReweight, variants: variantsPayload },
                {
                  onSuccess: () => {
                    onSuccess?.();
                    modal.close();
                  },
                },
              );
              onSubmit?.({ id: abTest.id, autoReweight, variants });
            }}
            disabled={isDirty && (!isValid || isLoading)}
          >
            {isLoading ? 'Saving changes...' : 'Save changes'}
          </Button>
          <Modal.Cancel onClick={onCancel} disabled={isLoading} />
        </Modal.Actions>
      ) : null}
    </Modal>
  );
}
