export const SAMPLING_TYPE = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL',
};

export const NUMBER_TYPE = {
  RATE: 'RATE',
  STATIC: 'STATIC',
};

export const DAYS_IN_MONTH = 30.4;
export const SAMPLING_WARNING = {
  MID: 10,
  HIGH: 20,
};

export const PAGE_TARGETING_ENABLED_TYPE = {
  RECORD_ALL: 'RECORD_ALL',
  RECORD_SPECIFIC: 'RECORD_SPECIFIC',
  RECORD_BY_JS: 'RECORD_BY_JS',
};
