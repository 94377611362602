import { gql } from '@urql/core';
import { produce } from 'immer';

import { notificationsFeatureKeys } from './queries';
import { defaultMutationFuction } from '../../graphql/defaultMutationFuction';

export const createNotificationMutation = ({ client, queryClient }) => ({
  mutationFn: ({ action, channelId, channelDestination, channelType, resource, resourceId }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation NotificationCreate(
          $action: NotificationAction!
          $channelId: Int
          $channelDestination: String
          $channelType: ChannelInputType!
          $resource: NotificationResource!
          $resourceId: String!
        ) {
          notificationCreate(
            action: $action
            channelId: $channelId
            channelDestination: $channelDestination
            channelType: $channelType
            resource: $resource
            resourceId: $resourceId
          ) {
            action
            channel {
              id
              destination
              type
            }
            createdAt
            createdBy {
              name
            }
            enabled
            id
            resource
            resourceId
            resourceName
            siteId
          }
        }
      `,
      {
        action,
        channelId,
        channelDestination,
        channelType,
        resource,
        resourceId,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: notificationsFeatureKeys.notificationsList._def });
    queryClient.invalidateQueries({ queryKey: notificationsFeatureKeys.channelsList._def });
  },
});

export const editNotificationMutation = ({ client, queryClient }) => ({
  mutationFn: ({ notificationId, siteId, channelId, channelDestination }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation NotificationEdit($notificationId: Int!, $siteId: Int!, $channelId: Int, $channelDestination: String) {
          notificationEdit(
            notificationId: $notificationId
            siteId: $siteId
            channelId: $channelId
            channelDestination: $channelDestination
          ) {
            action
            channel {
              id
              destination
              type
            }
            createdAt
            createdBy {
              name
            }
            enabled
            id
            resource
            resourceId
            resourceName
            siteId
          }
        }
      `,
      {
        notificationId,
        siteId,
        channelId,
        channelDestination,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: notificationsFeatureKeys.notificationsList._def });
    queryClient.invalidateQueries({ queryKey: notificationsFeatureKeys.channelsList._def });
  },
});

export const deleteNotificationMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation NotificationDelete($ids: [Int!]!) {
          notificationDelete(ids: $ids) {
            affected
          }
        }
      `,
      {
        ids,
      },
    ),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: notificationsFeatureKeys.notificationsList._def });
  },
});

export const notificationToggleEnabledMutation = ({ client, queryClient }) => ({
  mutationFn: ({ ids, enabled }) =>
    defaultMutationFuction(
      client,
      gql`
        mutation NotificationToggleEnabled($ids: [Int!]!, $enabled: Boolean!) {
          notificationToggleEnabled(ids: $ids, enabled: $enabled) {
            affected
          }
        }
      `,
      {
        ids,
        enabled,
      },
    ),
  onMutate: async (variables) => {
    if (variables.ids.length === 1) {
      await Promise.all([queryClient.cancelQueries({ queryKey: notificationsFeatureKeys.notificationsList._def })]);

      const previousStatus =
        queryClient
          .getQueriesData({ queryKey: notificationsFeatureKeys.notificationsList._def })
          .flatMap(([, data]) => data?.pages.flatMap((page) => page.notificationsList.list))
          .filter(Boolean)
          .find((n) => n.id === variables.ids[0])?.enabled ?? false;

      queryClient.setQueriesData({ queryKey: notificationsFeatureKeys.notificationsList._def }, (old) =>
        old
          ? produce(old, (draft) => {
              for (let i = 0; i < old.pages.length; i++) {
                const j = old.pages[i].notificationsList.notifications.findIndex((n) => n.id === variables.ids[0]);
                if (j > -1) {
                  draft.pages[i].notificationsList.notifications[j].enabled = variables.enabled;
                }
              }
            })
          : null,
      );

      return { previousStatus, id: variables.ids[0] };
    }
  },
  onError: (_, variables, context) => {
    if (variables.ids.length === 1) {
      queryClient.setQueriesData({ queryKey: notificationsFeatureKeys.notificationsList._def }, (old) =>
        old
          ? produce(old, (draft) => {
              for (let i = 0; i < old.pages.length; i++) {
                const j = old.pages[i].notificationsList.notifications.findIndex((n) => n.id === variables.ids[0]);
                if (j > -1) {
                  draft.pages[i].notificationsList.notifications[j].enabled = context.previousStatus;
                }
              }
            })
          : null,
      );
    }
  },
  onSettled: () => {
    queryClient.invalidateQueries({ queryKey: notificationsFeatureKeys.notificationsList._def });
  },
});
