import { useSite, usePermissions } from '/src/hooks';

import { SitePickerBar } from '/src/components/site-picker/index';
import { NotificationsContent } from '../components/NotificationsContent';
import { FEATURES } from '/src/features/_global/constants';
import { NotificationsFilterProvider } from '../notifications-filter-context';

// eslint-disable-next-line no-restricted-syntax
export default function Notifications() {
  const { sites, selectedSite, selectSite, loadingSites } = useSite({ enableAllSites: true });

  const permissions = usePermissions();
  const canEditSites = permissions.can('manageSites', FEATURES.SITE_SETTINGS).allowed;

  return (
    <NotificationsFilterProvider>
      <div className="-mx-10 mb-10 -mt-9">
        <SitePickerBar
          sites={sites}
          selectedSite={selectedSite}
          selectSite={selectSite}
          loading={loadingSites}
          addSiteOption={canEditSites}
          enableAllSites={true}
          pageDisplayName="Notifications dashboard"
        />
      </div>
      <NotificationsContent />
    </NotificationsFilterProvider>
  );
}
