import { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Popover, StyledPopoverPanel, Indicator, PopoverItem } from '@crazyegginc/hatch';

import { usePermissions, useModal, useMutation } from '/src/hooks';
import { getSessionQueryParameters } from '/src/utils/url';
import { ROOT_ELEMENT_ID } from '/src/features/_global/constants';
import { AB_TEST_STATUSES, abTestTypes } from '/src/features/ab-testing/constants';
import { FEATURES } from '/src/features/_global/constants';
import { SHARABLE_RESOURCE_TYPES } from '/src/features/team-and-sharing/constants';
import { abTestDuplicateMutation } from '/src/features/ab-testing/mutations';

import { AdjustWeightsModal } from '/src/features/ab-testing/components/modals/AdjustWeights';
import { StopTestModal } from '/src/features/ab-testing/components/modals/StopTest';
import { DeleteTestModal } from '/src/features/ab-testing/components/modals/DeleteTest';
import { AddSplitVariant } from '/src/features/ab-testing/components/modals/AddSplitVariant';
import { AbTestRenameModal } from '/src/features/ab-testing/components/modals/AbTestRenameModal';
import { SharePreviewModal } from './modals/SharePreview';

import { ReactComponent as PlusIcon } from '@crazyegginc/hatch/dist/images/icon-plus.svg';
import { ReactComponent as CogIcon } from '@crazyegginc/hatch/dist/images/icon-cog-filled.svg';
import { ReactComponent as ShareIcon } from '@crazyegginc/hatch/dist/images/icon-share-outline.svg';
import { ReactComponent as TrashIcon } from '@crazyegginc/hatch/dist/images/icon-remove-filled.svg';
import { ReactComponent as StopIcon } from '@crazyegginc/hatch/dist/images/icon-stop-outline.svg';
import { ReactComponent as GoalIcon } from '@crazyegginc/hatch/dist/images/icon-goal-filled.svg';
import { ReactComponent as AutoAdjustedIcon } from '@crazyegginc/hatch/dist/images/icon-traffic-outline.svg';
import { ReactComponent as ChartIcon } from '@crazyegginc/hatch/dist/images/icon-chart-filled.svg';
import { ReactComponent as EditIcon } from '@crazyegginc/hatch/dist/images/icon-pencil-filled.svg';
import { ReactComponent as DuplicateIcon } from '@crazyegginc/hatch/dist/images/icon-web-pages-outline.svg';

export function AbTestActionButton({ entity, redirectOnDelete = null, refetch = null }) {
  const modal = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = usePermissions();
  const canEdit = permissions.can('edit', FEATURES.AB_TESTING).allowed;
  const [duplicateStatus, setDuplicateStatus] = useState('idle');

  const { mutateAsync: duplicateTest } = useMutation(abTestDuplicateMutation);

  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: 'bottom',
          fallbackPlacements: 'bottom',
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
      useMemo(
        () => ({
          name: 'offset',
          options: {
            offset: ({ popper, reference }) => {
              return [-popper.width / 2 + reference.width / 2, -4];
            },
          },
        }),
        [],
      ),
    ],
  });

  if (!canEdit || !entity) return null;

  const isDraft = entity.status === AB_TEST_STATUSES.DRAFT;
  const isStopped = entity.status === AB_TEST_STATUSES.STOPPED;

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <div ref={setReferenceElement}>
            <Popover.Button
              className={classNames(
                'flex h-[35px] w-[70px] items-center justify-center rounded px-4 text-dodger-blue-300',
                {
                  'border border-mystic-500 bg-white shadow-md': open,
                },
              )}
            >
              <CogIcon aria-label="actions" className="h-[18px] w-[18px] fill-current" />
              <Indicator aria-label="expand" type="dropdown" className="ml-2.5" />
            </Popover.Button>
          </div>

          {open &&
            createPortal(
              <StyledPopoverPanel
                ref={setTooltipElement}
                // onMouseDown={(e) => e.stopPropagation()}
                className="w-60"
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {() => (
                  <>
                    <div className="absolute -right-px -top-1.25 z-[12] h-2.5 w-[69px] border-r border-mystic-500 bg-white" />

                    {!isDraft ? (
                      <Item
                        disabled={!entity.permissions.canStop}
                        onClick={() => {
                          modal.show(<StopTestModal abTest={entity} />, { width: '335px' });
                        }}
                        icon={StopIcon}
                      >
                        End this test
                      </Item>
                    ) : null}

                    {entity.permissions.canDuplicate ? (
                      <Item
                        disabled={duplicateStatus === 'pending'}
                        onClick={async () => {
                          setDuplicateStatus('pending');
                          const result = await duplicateTest({ id: entity.id });

                          const id = result?.abTestDuplicate?.id;

                          if (id) {
                            setTimeout(() => {
                              navigate(`/ab-tests/${id}/edit`);
                            }, 100);
                          }
                          setDuplicateStatus('idle');
                        }}
                        icon={DuplicateIcon}
                      >
                        {duplicateStatus === 'pending' ? 'Duplicating...' : 'Duplicate test'}
                      </Item>
                    ) : null}

                    {!isDraft ? (
                      <Item
                        disabled={!entity.permissions.canRename}
                        onClick={() => {
                          close();
                          modal.show(<AbTestRenameModal abTest={entity} onSuccess={() => refetch?.()} />);
                        }}
                        icon={EditIcon}
                      >
                        Rename test
                      </Item>
                    ) : null}

                    <Item
                      onClick={() => {
                        modal.show(<SharePreviewModal variants={entity.variants} />);
                      }}
                      icon={ShareIcon}
                    >
                      Share test preview
                    </Item>

                    {!isDraft ? (
                      <Item
                        onClick={() => {
                          window.SharingModal.show({
                            entity: {
                              id: parseInt(entity.id),
                              siteId: parseInt(entity.siteId),
                              type: SHARABLE_RESOURCE_TYPES.ABTEST,
                            },
                          });
                        }}
                        icon={ChartIcon}
                      >
                        Share test results
                      </Item>
                    ) : null}

                    {!isDraft ? (
                      <Item
                        disabled={!entity.permissions.canChangeGoal}
                        onClick={() => {
                          navigate({
                            pathname: `/ab-tests/${entity?.id}/goals`,
                            state: { previousPath: location.pathname },
                          });
                        }}
                        icon={GoalIcon}
                      >
                        Change goal
                      </Item>
                    ) : null}

                    {!isDraft ? (
                      <Item
                        disabled={!entity.permissions.canAddVariants}
                        onClick={() => {
                          if (entity.type === abTestTypes.SPLIT) {
                            return modal.show(<AddSplitVariant abTest={entity} />);
                          }
                          navigate({
                            pathname: `/ab-tests/${entity?.id}/variants/new`,
                            search: getSessionQueryParameters(),
                            state: { previousPath: location.pathname },
                          });
                        }}
                        icon={PlusIcon}
                      >
                        Add new variant
                      </Item>
                    ) : null}

                    {!isDraft && !isStopped ? (
                      <Item
                        disabled={!entity.permissions.canAdjustWeight}
                        onClick={() => {
                          close();
                          modal.show(<AdjustWeightsModal abTest={entity} onSuccess={() => refetch?.()} />);
                        }}
                        icon={AutoAdjustedIcon}
                      >
                        Adjust traffic split
                      </Item>
                    ) : !isDraft ? (
                      <Item
                        disabled={false}
                        onClick={() => {
                          close();
                          modal.show(
                            <AdjustWeightsModal abTest={entity} onSuccess={() => refetch?.()} readOnly={true} />,
                          );
                        }}
                        icon={AutoAdjustedIcon}
                      >
                        View split details
                      </Item>
                    ) : null}

                    <Item
                      disabled={!entity.permissions.canDelete}
                      onClick={() => {
                        modal.show(<DeleteTestModal abTest={entity} redirectOnDelete={redirectOnDelete} />, {
                          width: '450px',
                        });
                      }}
                      icon={TrashIcon}
                    >
                      Delete {isDraft ? 'draft' : 'test'}
                    </Item>
                  </>
                )}
              </StyledPopoverPanel>,
              document.querySelector(`#${ROOT_ELEMENT_ID}`),
            )}
        </>
      )}
    </Popover>
  );
}

function Item({ children, icon: Icon, className, disabled, ...props }) {
  return (
    <PopoverItem
      className={classNames('!flex !select-none !items-center !py-2', className, {
        'hover:!text-body-5 !cursor-not-allowed !bg-white-lilac-500 !italic': disabled,
        'group ': !disabled,
      })}
      disabled={disabled}
      {...props}
    >
      {Icon && (
        <Icon
          className={classNames('mr-5 h-4 w-4 fill-current group-hover:text-dodger-blue-500', {
            'text-cadet-blue-500': disabled,
            'text-dodger-blue-300': !disabled,
          })}
        />
      )}
      {children}
    </PopoverItem>
  );
}
