import NotificationsPNG from '@crazyegginc/hatch/dist/images/illustration-page-notification.png';
import NotificationsWebP from '@crazyegginc/hatch/dist/images/illustration-page-notification.webp';

import { Paywall } from '/src/components/Paywall';
import { SupportLinks } from '/src/support';

export function NoNotificationsWall() {
  return (
    <Paywall>
      <Paywall.Image webp={NotificationsWebP} fallback={NotificationsPNG} width={'160px'} />
      <Paywall.Title>No Notifications</Paywall.Title>

      <Paywall.Body>
        <p style={{ textWrap: 'balance' }}>
          You don’t currently have any notifications.{' '}
          <a href={SupportLinks.notifications.create} target="_blank" rel="noopener noreferrer" className="text-link">
            Learn how to create notifications
          </a>
          .
        </p>
      </Paywall.Body>
    </Paywall>
  );
}
