import { useModal, useNotifications } from '/src/hooks';
import { ABTEST } from '/src/features/ab-testing/constants';
import { PreviewLoadingModal } from '/src/features/_global/modals/PreviewLoadingModal';
import { useCallback } from 'react';

export function PreviewLink({ variant, children }) {
  const modal = useModal();
  const notifications = useNotifications();

  const launchPreview = useCallback(
    ({ viewVariantUrl }) => {
      function handleMessage(e) {
        if (e.data.status === 'transformed') {
          window.removeEventListener('message', handleMessage);
          modal.close();
        }
      }

      window.addEventListener('message', handleMessage);

      const previewWindow = window.open(viewVariantUrl, `_blank`);

      if (!previewWindow) {
        console.warn(`[A/B Test Preview] Could not open the preview. Check your browser to see if any popup blockers are preventing this window from opening new tabs.`); // eslint-disable-line
        notifications.info({
          title: `Couldn't open your variant preview`,
          content: `Something prevented us from opening a new tab to your site.`,
          timeout: 8000,
          skipHoneybadger: true,
        });
      }
    },
    [modal, notifications],
  );

  if (!variant.id || !variant.viewVariantUrl) {
    // we need the variant to be stored for the link to work
    return null;
  }

  return (
    <button
      onClick={() => {
        modal.show(<PreviewLoadingModal type={ABTEST} target="tab" spelledType="A/B Test" />);
        launchPreview(variant);
      }}
    >
      {children}
    </button>
  );
}
