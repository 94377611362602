import { FEATURES, PERMISSION_NOT_ALLOWED, ALLOWED } from '/src/features/_global/constants';
import { NOTIFICATIONS_PERMISSIONS } from '../notifications/permissions';

export const OPTIONS_PERMISSIONS = {
  [FEATURES.OPTIONS]: ({ capabilities }) => ({
    navigate: capabilities?.siteSettings.permissions.nav,
  }),
  [FEATURES.SITE_SETTINGS]: ({ capabilities, currentUser }) => ({
    navigate: capabilities?.siteSettings.permissions.nav,
    edit: capabilities?.siteSettings.permissions.edit,
    manageSites: capabilities?.siteSettings.permissions.manageSites,
    manageSiteSettings: currentUser?.features.includes('site-settings') ? ALLOWED : PERMISSION_NOT_ALLOWED,
  }),
  [FEATURES.TRACKING_OPTIONS]: ({ capabilities }) => ({
    navigate: capabilities?.trackingOptions.permissions.nav,
    edit: capabilities?.trackingOptions.permissions.edit,
  }),
  [FEATURES.API]: ({ capabilities }) => ({
    navigate: capabilities?.api.permissions.nav,
    edit: capabilities?.api.permissions.edit,
  }),
  [FEATURES.INTEGRATIONS]: ({ capabilities }) => ({
    navigate: capabilities?.integrations.permissions.nav,
    edit: capabilities?.integrations.permissions.edit,
  }),
  ...NOTIFICATIONS_PERMISSIONS,
};
