import { forwardRef } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@crazyegginc/hatch';

export const ActionText = forwardRef(({ text, onClick, tooltip }, ref) => {
  return (
    <div className="ml-6 first:ml-0">
      <Tooltip tooltipContent={tooltip ?? ''} offset="25" show={!!tooltip}>
        <button
          className="group flex min-h-[24px] min-w-[24px] items-center justify-center rounded-full py-2 px-3 hover:bg-wedgewood-500 focus-visible:outline-white"
          type="button"
          onClick={onClick}
          ref={ref}
        >
          <span className={classNames('shrink-0 text-white brightness-75 group-hover:brightness-100 font-semibold')}>
            {text}
          </span>
        </button>
      </Tooltip>
    </div>
  );
});
